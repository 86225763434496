<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-form @submit.prevent="submitForm" ref="form">
          <v-card width="400" class="mx-auto mt-5">
            <v-card-title class="pb-0">
              <h3>Réinitialisation du mot de passe</h3>
            </v-card-title>
            <v-card-text v-if="!success">

              <v-alert v-if="error"
                v-model="error"
                border="left"
                dismissible
                type="error"
              >{{errorText}}</v-alert>

              <v-text-field
                v-model.trim="email"
                autofocus
                label="Adresse email"
                hide-details
                prepend-icon="mdi-at"
                class="shrink"
              ></v-text-field>

            </v-card-text>
            <v-card-text v-else>
              <v-alert border="left"
                type="success"
              >Lien de changement de mot de passe envoyé avec succés</v-alert>

              <p>
                Vous allez recevoir un message contenant un lien permettant de redéfinir votre mot de passe.
                Vérifiez votre boite de réception de courrier électronique.
              </p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="goLoginPage" color="primary" text>
                Se connecter
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="success" v-if="!success">
                Envoyer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      email: '',
      error: false,
      errorText: '',
      success: false
    };
  },

  methods: {
    submitForm: function() {
      //this.$refs.form.validate();
      
      // initialize error alert
      this.error = false;

      const self = this;
      const { email } = this;

      // @todo send an email with link format : http://localhost:8080/#/reset-password?selector=x&token=y
      this.$store.dispatch('sendResetPasswordLinkRequest', { email })
        .then(function() {
          self.success = true; // success notification
        })
        .catch(function(error) {
          self.errorText = error.message;
          // get server response
          if (error.response.data && error.response.data.message) {
            self.errorText = error.response.data.message;
          }
          self.error = true;
        });
    },
    goLoginPage: function() {
      this.$router.push('/login');
    }
  }
};
</script>