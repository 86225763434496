<template>
  <v-btn v-if="!connected"
    color="error"
    elevation="0"
    fab
    small
  >
    <v-icon
      :color="color"
      medium
    >{{connected && 'mdi-access-point-network' || 'mdi-access-point-network-off'}}</v-icon>
  </v-btn>
</template>

<script>
export default {
  data: function() {
    return {
      connected: false,
      counter: 0,
      now: Date.now(),
      previousTime: null
    };
  },

  props: [
    'mqttData'
  ],

  computed: {
    color: function() {
      return (this.connected === true) ? 'red' : 'white';
    }
  },

  watch: {
    now: function() {
      if (this.counter < 3) {
        this.connected = false;
      }

      this.counter = 0;
      this.connected = true;
    },

    mqttData: function(/*newVal, oldVal*/) {
      const time = this.mqttData['time'];
      if (time != this.previousTime) {
        this.previousTime = time;
        this.counter++;
      }

/*
      const now = new Date();
      let v = time.replace(/000$/g, "Z");
      v = v.replace(/(:[0-9]{2})$/g, "$1.000Z");
      const u = new Date(Date.parse(v));
      //console.info('Delta:', Math.floor((u.getTime() - now.getTime()) / 1000), 'seconds');
      console.info('Delta:', (u.getTime() - now.getTime()) / 1000, 'seconds');
*/
    }
  },

  created: function() {
    var self = this
    setInterval(function () {
      self.now = Date.now()
    }, 2000)
  }

  // timeout mqtt (see mqttjs callbacks)
  // -> broker (mosquitto process crash)
  // -> physical server (connection, dns, crash...)
  // -> (local) internet connection

  // timeout on coopta/dryer/raw['time']
  // -> same result with other keys because time is sent on each modbus packet

  // timeout on coopta/dryer/raw['all_keys']
  // -> dryer controller fails to communicate via modbus
  // -> python crash
  // -> esp does not send mqtt messages

  // if getting sysinfo/version
  // -> OK broker (mosquitto process crash)
  // -> OK physical server (connection, dns, crash...)
  // -> OK (local) internet connection
  // -> OK esp send mqtt messages
  // -> KO? dryer controller fails to communicate via modbus
  // -> KO? python crash
      
// coopta/146/dryer/*
// coopta/146/experiments/thermo/1/{temperature|humidity}
}
</script>