<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-form @submit.prevent="login" ref="form">
          <v-card width="400" class="mx-auto mt-5">
            <v-card-title class="pb-0">
              <h3>Connexion</h3>
            </v-card-title>
            <v-card-text>

              <v-alert v-if="error"
                v-model="error"
                border="left"
                dismissible
                type="error"
              >{{errorText}}</v-alert>

              <v-text-field
                v-model.trim="username"
                autofocus
                label="Nom d'utilisateur"
                hide-details
                prepend-icon="mdi-account-circle"
                ref="username"
                class="shrink"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                label="Mot de passe"
                hide-details
                prepend-icon="mdi-lock"
                class="shrink"
                @click:append="show = !show"
              ></v-text-field>

              <v-checkbox
                v-model="remember"
                label="Rester connecter"
              ></v-checkbox>

              <a @click.prevent="goLostPasswordPage" color="primary">
                Mot de passe oublié ?
              </a>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="goRegisterPage" color="primary" text>
                S'enregistrer
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="success">
                Se connecter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  beforeRouteUpdate(/*to, from, next*/) {
    // autofocus username field on route change
    this.$refs.username.focus();
  },

  data: function() {
    return {
      error: false,
      errorText: '',
      password: '',
      remember: false,
      show: false,
      username: ''
    };
  },

  methods: {
    login: function() {
      //this.$refs.form.validate();
      // initialize error alert
      this.error = false;

      const self = this;
      const { username, password, remember } = this;
      this.$store.dispatch('loginRequest', { username, password, remember })
        .then(function() {
          if (self.$route.query.redir != null) {
            self.$router.push(self.$route.query.redir);
          } else {
            self.$router.push('/');
          }
        })
        .catch(function(error) {
          self.errorText = error.message;
          // get server response
          if (error.response.data && error.response.data.message) {
            self.errorText = error.response.data.message;
          }
          self.error = true;
        });
    },
    goLostPasswordPage: function() {
      this.$router.push('/lost-password');
    },
    goRegisterPage: function() {
      this.$router.push('/register');
    }
  }
};
</script>