import Vue from 'vue';

/**
 * notification: {
 *   id: int,
 *   text: string,
 *   type: string
 * }
 */
const state = {
  notifications: []
};

const getters = {
  getAll: function(state) {
    return state.notifications;
  },
  getCount: function(state) {
    return state.notifications.length;
  },
  getFirst: function(state) {
    return state.notifications[0];
  },
  getLatest: function(state) {
    return state.notifications[state.notifications.length - 1];
  }
};

const actions = {
  add: ({ commit }, data) => {
    commit('ADD_NOTIFICATION', data);
  },
  remove: ({ commit }, data) => {
    commit('REMOVE_NOTIFICATION', data);
  }
};

const mutations = {
  ADD_NOTIFICATION: (state, data) => {
    const notifications = state.notifications;
    notifications.push(data);
    Vue.set(state, 'notifications', notifications);
  },
  REMOVE_NOTIFICATION: (state) => {
    const notifications = state.notifications;
    notifications.pop(); // @todo or shift()
    Vue.set(state, 'notifications', notifications);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};