import http from '@/utils/http';

class AuthDataService {
  setToken(token) { // @todo
    http.defaults.headers.common['Authorization'] = token;
  }

  login(data) {
    return http.post('/auth/login', data);
  }

  logout() {
    return http.post('/auth/logout');
  }

  register(data) {
    return http.post('/auth/register', data);
  }

  confirmEmail(data) {
    return http.get('/auth/confirm-email', { params: data });
  }

  sendResetPasswordLink(data) {
    return http.post('/auth/lost-password', data);
  }

  resetLostPassword(data) {
    return http.post('/auth/lost-password-change', data);
  }

  getCurrentUser() {
    return http.get('/auth');
  }
}

export default new AuthDataService();