<template>
  <VariablesTable :mqttData="mqttData" />
</template>

<script>
import VariablesTable from '@/components/VariablesTable';

export default {
  name: 'debug-view',

  components: {
    VariablesTable
  },

  props: [
    'mqttData'
  ]
}
</script>