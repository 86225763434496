<template>
  <v-container>
    <h3>Paramètrages des forçages</h3>

    <v-alert
      border="left"
      outlined
      type="warning"
    >
      En construction : l'état est affiché mais ne peut pas être défini pour le moment.
    </v-alert>

    <v-alert
      border="left"
      colored-border
      elevation="2"
      type="info"
    >
      Force l'arrêt du composant ou son fonctionnement en mode automatique.
    </v-alert>

    <v-switch
      v-model="elements.burnerInf"
      color="red"
      disabled
      hide-details
      inset
      label="Brûleur inférieur"
    ></v-switch>
    <v-switch
      v-model="elements.burnerSup"
      color="red"
      disabled
      hide-details
      inset
      label="Brûleur supérieur"
    ></v-switch>
    <v-switch
      v-model="elements.timer"
      color="red"
      disabled
      hide-details
      inset
      label="Décompte intervalle d'extraction"
    ></v-switch>
    <v-switch
      v-model="elements.fan"
      color="red"
      disabled
      hide-details
      inset
      label="Ventilateur principal"
    ></v-switch>
    <v-switch
      v-model="elements.antidust"
      color="red"
      disabled
      hide-details
      inset
      label="Système de dépoussièrage"
    ></v-switch>
    <v-switch
      v-model="elements.horn"
      color="red"
      disabled
      hide-details
      inset
      label="Klaxon"
    ></v-switch>
  </v-container>
</template>

<script>
export default {
  name: 'forcings-view',

  props: [
    'mqttData'
  ],

  computed: {
    elements: function() {
      let value = 0;

      if (typeof this.mqttData['40121'] !== 'undefined') {
        value = this.mqttData['40121'].value;
      }

      const MASK = {
        fan:       0b00000001, // 0x0001
        antidust:  0b00010000, // 0x0010
        timer:     0b00100000, // 0x0020
        burnerInf: 0b01000000, // 0x0040
        burnerSup: 0b10000000, // 0x0080
        horn:  0b001000000000  // 0x0200
      };

      return {
        fan: (value & MASK.fan) !== 0,
        antidust: (value & MASK.antidust) !== 0,
        timer: (value & MASK.timer) !== 0,
        burnerInf: (value & MASK.burnerInf) !== 0,
        burnerSup: (value & MASK.burnerSup) !== 0,
        horn: (value & MASK.horn) !== 0
      };
    }
  }
}
</script>