<template>
  <v-list-item :class="backgroundColor + ' sensor-element-background'">
    <v-list-item-content>
      <v-list-item-title class="font-weight-light">
        {{sensorName}}
      </v-list-item-title>
      <v-list-item-subtitle>
        <span class="font-weight-black">{{sensorCurrentValue}}°C</span> / {{sensorLimit}}°C
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="d-none d-sm-flex">
      <SensorChartDialog :sensor="sensor" />
    </v-list-item-action>
    <v-list-item-action class="d-none d-sm-flex">
      <v-switch v-if="sensor"
        @change="toggleState()"
        color="primary"
        :disabled="disabled"
        :input-value="sensorEnabled"
        :loading="loading"
      ></v-switch>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import SensorChartDialog from '@/components/SensorsTable/SensorChartDialog';

export default {
  name: 'sensor-table-element',

  components: {
    SensorChartDialog
  },

  props: [
    'sensor'
  ],

  data: function() {
    return {
      disabled: false,
      loading: false
    };
  },

  computed: {
    backgroundColor: function() {
      if (typeof this.sensor === 'undefined') return 'white';

      // @todo can be undefined?
      const temperature = (typeof this.sensor.current !== 'undefined') ? this.sensor.current : null;
      let limit = (typeof this.sensor.limit !== 'undefined') ? this.sensor.limit : null;

      if (temperature === null || limit === null) {
        return 'grey lighten-2';
      } else if (temperature >= limit) {
        return 'red lighten-4';
      } else if (temperature > limit - 5 && temperature < limit) {
        return 'amber lighten-4';
      } else {
        return 'green lighten-4';
      }
    },

    sensorCurrentValue: function() {
      if (typeof this.sensor === 'undefined') return '-';
      return this.sensor.current.toFixed(1);
    },

    sensorEnabled: {
      get: function() {
        return this.sensor.enabled;
      },
      set: function(val) {
        return val;
      }
    },

    sensorLimit: function() {
      if (typeof this.sensor === 'undefined') return '-';
      return this.sensor.limit.toFixed(1);
    },

    sensorName: function() {
      if (typeof this.sensor === 'undefined') return 'Chargement...';
      return this.sensor.name;
    }
  },

  methods: {
    // on switch click: disable the switch (and show loader) but show its state (from mqtt) in live
    // and push a request to send a modbus command to controller (toggle state)

    toggleState: function() {
      this.sensorEnabled = !this.sensorEnabled;
      this.loading = "primary";
      this.disabled = true;

      const promise = new Promise((resolve/*, reject*/) => {
        setTimeout(() => {
          resolve('foo');
        }, 3000);
      });

      promise.then((val) => {
        console.log('switch: modbus command ok', val);
        this.loading = false;
        this.disabled = false;
        //console.log(this.sensorEnabled, this.sensor.enabled);
        //this.sensorEnabled = this.sensor.enabled;
      });

      promise.catch((err) => {
        console.log('switch: modbus command ko!', err);
        //this.loading = false;
        //this.disabled = false;
        //this.sensorEnabled = this.sensor.enabled;
      });
    }
  }
}
</script>

<style scoped>
.theme--dark.sensor-element-background.grey {
  background-color: #212121 !important;
}
.theme--light.sensor-element-background.grey {
  background-color: #E0E0E0 !important;
}
.theme--dark.sensor-element-background.green {
  background-color: #1B5E20 !important;
}
.theme--light.sensor-element-background.green {
  background-color: #C8E6C9 !important;
}
.theme--dark.sensor-element-background.amber {
  background-color: #FF6F00 !important;
}
.theme--light.sensor-element-background.amber {
  background-color: #FFECB3 !important;
}
.theme--dark.sensor-element-background.red {
  background-color: #B71C1C !important;
}
.theme--light.sensor-element-background.red {
  background-color: #FFCDD2 !important;
}
</style>