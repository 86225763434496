<template>
  <v-dialog
    v-model="dialogHelp"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="red lighten-2"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Aide
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2">
        Aide
      </v-card-title>

      <v-card-text>
        <ul>
          <li>Le <span class="font-weight-medium">nom</span> et la <span class="font-weight-medium">description</span> d'une notification n'ont qu'un but informatif.</li>
          <li>La <span class="font-weight-medium">variable</span> est un nombre identifiant quel paramètre surveiller (voir dans le menu "Debug").</li>
          <li>
            L'<span class="font-weight-medium">opérateur</span> de comparaison et la <span class="font-weight-medium">référence</span> servent à déclencher la notification. Elle sera envoyée,
            par exemple, si l'opérateur "supérieur à" est sélectionné, qu'on met la référence à 20 et que la
            variable surveillée dépasse 20.
          </li>
          <li>Le <span class="font-weight-medium">canal</span> permet de choisir par quel biais envoyer la notification.</li>
          <li>Le <span class="font-weight-medium">destinataire</span>...</li>
          <li>
            Le <span class="font-weight-medium">modèle</span> permet de définir le contenu de la notification, il est possible d'afficher certains
            contenus dynamiquement, par exemple le contenu de la variable surveillée avec le mot-clé &lt;%valeur%&gt;.
          </li>
          <li>
            L'<span class="font-weight-medium">intervalle</span> permet de limiter le nombre de notifications envoyées sur un intervalle de temps donné,
            dans le but d'éviter d'être spammé si la condition "variable/opérateur/référence" reste vraie.
          </li>
          <li>
            Le bouton d'<span class="font-weight-medium">activation</span> permet simplement d'activer ou non l'envoi de la notification sans la supprimer.
          </li>
        </ul>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialogHelp = false"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: function() {
    return {
      dialogHelp: false
    }
  }
}
</script>