import Vue from 'vue';

/**
 * fab: {
 *   action: string,
 *   color: string,
 *   icon: string
 * }
 */
const state = {
  clicked: false,
  context: {
    action: null,
    color: 'red',
    icon: 'mdi-stop'
  }
};

const getters = {
  get: function(state) {
    return state.context;
  },
  isClicked(state) {
    return state.clicked;
  }
};

const actions = {
  click:  ({ commit }, data) => {
    commit('SET_CLICKED', data);
  },
  set: ({ commit }, data) => {
    commit('SET_CONTEXT', data);
  }
};

const mutations = {
  SET_CLICKED: (state, data) => {
    Vue.set(state, 'clicked', data);
  },
  SET_CONTEXT: (state, data) => {
    Vue.set(state, 'context', data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};