import axios from 'axios';

axios.defaults.withCredentials = true; // @todo

const http = axios.create({
  baseURL: 'https://dryer.terreatlantique.com/api/v1.0', // @todo in configuration file
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export default http;