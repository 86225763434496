import Vue from 'vue';
import datetime from '@/utils/date';

const state = {
  range: {
    from: null,
    to: null,
    refreshInterval: 0
  }
};

const getters = {
  getRange: function(state) {
    if (!state.range.from) state.range.from = new Date(Date.now() - 1800 * 1000).toISOString(); // time in seconds
    if (!state.range.to) state.range.to = new Date().toISOString(); // now
    return state.range;
  },
  getRangeLocal: function(state) {
    // format date in the current timezone
    return {
      from: datetime.formatToLocal(state.range.from),
      to: datetime.formatToLocal(state.range.to)
    };
  },
  getRangeDatetimeLocal: function(state) {
    // format date in the current timezone
    return {
      from: datetime.formatToDatetimeLocal(state.range.from),
      to: datetime.formatToDatetimeLocal(state.range.to)
    };
  },
  getRefreshInterval: function(state) {
    return state.refreshInterval;
  }
};

const actions = {
  updateRange: ({ commit }, data) => {
    commit('SET_CHART_RANGE', data);
  },
  updateRefreshInterval: ({ commit }, data) => {
    commit('SET_CHART_REFRESH_INTERVAL', data);
  }
};

const mutations = {
  SET_CHART_RANGE: (state, data) => {
    Vue.set(state, 'range', data);
  },
  SET_CHART_REFRESH_INTERVAL: (state, data) => {
    Vue.set(state, 'refreshInterval', data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};