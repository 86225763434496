<template>
  <v-container fluid>
    <Alert id="info2" type="info">
      L'arrière-plan de chaque sonde est coloré en fonction de l'écart entre sa température actuelle
      et le seuil d'alarme qui lui est assigné. En vert lorsque la température est correcte, en orange
      lorsqu'elle est à 5°C du seuil, et en rouge lorsqu'elle le dépasse.
    </Alert>

    <v-row dense>
      <v-col>
        <h4>Sondes supérieures</h4>
      </v-col>
    </v-row>
    <v-row dense>

      <v-col>
        <v-list two-line outlined>
          <SensorElement :sensor="sensors[8]"/>
          <SensorElement :sensor="sensors[9]"/>
          <SensorElement :sensor="sensors[10]"/>
          <SensorElement :sensor="sensors[11]"/>
        </v-list>
      </v-col>

      <v-col>
        <v-list two-line outlined>
          <SensorElement :sensor="sensors[12]"/>
          <SensorElement :sensor="sensors[13]"/>
          <SensorElement :sensor="sensors[14]"/>
          <SensorElement :sensor="sensors[15]"/>
        </v-list>
      </v-col>

    </v-row>
    <v-row dense>
      <v-col>
        <h4>Sondes inférieures</h4>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-list two-line outlined>
          <SensorElement :sensor="sensors[0]"/>
          <SensorElement :sensor="sensors[1]"/>
          <SensorElement :sensor="sensors[2]"/>
          <SensorElement :sensor="sensors[3]"/>
        </v-list>
      </v-col>

      <v-col>
        <v-list two-line outlined>
          <SensorElement :sensor="sensors[4]"/>
          <SensorElement :sensor="sensors[5]"/>
          <SensorElement :sensor="sensors[6]"/>
          <SensorElement :sensor="sensors[7]"/>
        </v-list>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import Alert from '@/components/Alert';
import SensorElement from '@/components/SensorsTable/SensorElement';

export default {
  props: [
    'mqttData'
  ],

  components: {
    Alert,
    SensorElement
  },

  computed: {
    // convert mqtt raw data to an array of sensors
    sensors: function() {
      let formattedSensors = [];

      // first mqtt message not received yet
      if (Object.keys(this.mqttData).length === 0) {
        return formattedSensors;
      }

      // live values
      for (let id = 40501; id <= 40516; id++) {
        formattedSensors[id - 40501] = {
          id: id,
          name: this.mqttData[id].name,
          current: this.mqttData[id].value,
          limit: null,
          enabled: true
        };
      }

      // limits
      for (let id = 40601; id <= 40616; id++) {
        if (typeof formattedSensors[id - 40601] === 'undefined') {
          formattedSensors[id - 40601] = {}; // @todo ?
        }
        formattedSensors[id - 40601]['limit'] = this.mqttData[id].value;
      }

      // inhibition state
      let sensorsState1 = 0;
      let sensorsState2 = 0;

      if (typeof this.mqttData['40162'] !== 'undefined') {
        sensorsState1 = this.mqttData['40162'].value;
      }

      if (typeof this.mqttData['40163'] !== 'undefined') {
        sensorsState2 = this.mqttData['40163'].value;
      }

      formattedSensors[0]['enabled']  = (sensorsState1 & 1)   === 0; // 0b00000001
      formattedSensors[1]['enabled']  = (sensorsState1 & 2)   === 0; // 0b00000010
      formattedSensors[2]['enabled']  = (sensorsState1 & 4)   === 0;
      formattedSensors[3]['enabled']  = (sensorsState1 & 8)   === 0;
      formattedSensors[4]['enabled']  = (sensorsState1 & 16)  === 0;
      formattedSensors[5]['enabled']  = (sensorsState1 & 32)  === 0;
      formattedSensors[6]['enabled']  = (sensorsState1 & 64)  === 0;
      formattedSensors[7]['enabled']  = (sensorsState1 & 128) === 0; // 0b10000000
      formattedSensors[8]['enabled']  = (sensorsState2 & 1)   === 0; // 0b00000001
      formattedSensors[9]['enabled']  = (sensorsState2 & 2)   === 0;
      formattedSensors[10]['enabled'] = (sensorsState2 & 4)   === 0;
      formattedSensors[11]['enabled'] = (sensorsState2 & 8)   === 0;
      formattedSensors[12]['enabled'] = (sensorsState2 & 16)  === 0;
      formattedSensors[13]['enabled'] = (sensorsState2 & 32)  === 0;
      formattedSensors[14]['enabled'] = (sensorsState2 & 64)  === 0;
      formattedSensors[15]['enabled'] = (sensorsState2 & 128) === 0;

      return formattedSensors;
    }
  }
}
</script>