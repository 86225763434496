import http from '@/utils/http';

class UserDataService {
  getAll(data) {
    return http.get('/users', { params: data });
  }

  get(id) {
    return http.get(`/users/${id}`);
  }

  /*create(data) {
    return http.post('/users', data);
  }

  update(id, data) {
    return http.put(`/users/${id}`, data);
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }*/

  findByName(name) {
    return http.get(`/users?name=${name}`);
  }

  updateUserProfile(id, data) {
    return http.put(`/users/${id}/profile`, data);
  }
}

export default new UserDataService();