<template>
  <v-container>
    <h3>Etat du système</h3>

    <v-alert
      border="left"
      outlined
      type="warning"
    >
      En construction : incomplet.
    </v-alert>

    <p>Affichage de l'état de la connexion au broker MQTT (socket),<br>
      de l'état de la base de données,<br>
      de l'état du broker (variables $SYS$),<br>
      de l'état du worker "observer",<br>
      de l'état du worker "notifications_queue"
    </p>

    <v-expansion-panels
      focusable
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Pont MQTT ({{emitter.version}})
          <template v-slot:actions>
            <v-icon v-if="emitter.status == 'online'" color="green">mdi-check-circle</v-icon>
            <v-icon v-else color="red">mdi-alert-circle</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Etat du pont MQTT (émetteur). Il communique avec le système informatique
          et envoie l'état du séchoir sous forme de messages vers le broker MQTT.
          La panne de ce sous-système engendre l'impossibilité de voir l'état du
          séchoir en temps réel ainsi que l'arrêt de l'envoi des notifications.

          <ESP8266Status/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Base de données relationnelle
          <template v-slot:actions>
            <v-icon color="error">mdi-alert-circle</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Etat du serveur de base de données MariaDB. Il stocke les informations
          concernant l'authentification des utilisateurs, leurs préférences et
          le paramètrage des notifications.
          La panne de ce sous-système engendre l'impossibilité de se connecter à
          l'application ainsi que l'arrêt de l'envoi des notifications.
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Broker MQTT
          <template v-slot:actions>
            <v-icon color="error">mdi-alert-circle</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Etat du serveur de messagerie pub-sub. Il gère la diffusion des messages
          entre l'émetteur MQTT, l'application et ses sous-systèmes (gestion des
          notifications, affichage temps réel etc...).
          La panne de ce sous-système engendre l'impossibilité de voir l'état du
          séchoir en temps réel ainsi que l'arrêt de l'envoi des notifications.
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          File d'attente
          <template v-slot:actions>
            <v-icon color="error">mdi-alert-circle</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Etat du serveur de file d'attente. Il gère l'envoi des notifications
          de manière ordonnée en s'assurant qu'elles arrivent à destination.
          Il permet une tolérance aux pannes liées aux services de diffusion
          de méls, de messages push et de SMS car il va relancer les tâches
          d'envoi jusqu'à ce qu'elles aboutissent.
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Serveur physique
          <template v-slot:actions>
            <v-icon color="error">mdi-alert-circle</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Etat du serveur matériel.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import ESP8266Status from '@/components/ESP8266/Status';

export default {
  name: 'SystemStatus',

  components: {
    ESP8266Status
  },

  props: [
    'mqttData'
  ],

  data: function() {
    return {
      emitter: {}
    };
  },

  created: async function() {
    this.$store.dispatch('mqttOnMessage', this.onMqttMessage);

    await this.$store.dispatch('mqttSubscribe', 'coopta/dryer/devices/#');

    this.$store.dispatch('mqttPublish', {
      topic: 'coopta/dryer/commands/sysinfo',
      msg: 'get',
      cb: function(error) { console.log(error); }
    });
  },

  beforeDestroy: function() {
    let promise = this.$store.dispatch('mqttUnsubscribe', 'coopta/dryer/devices/#');
    promise.catch(function(error) {
      console.log(error);
    });
  },

  methods: {
    onMqttMessage: function(/*data, topic*/topic, data) {
      if (topic === 'coopta/dryer/devices/emitter/status') {
        const status = { status: data.toString() };
        this.emitter = Object.assign({}, this.emitter, status);
      }
      if (topic === 'coopta/dryer/devices/emitter/sysinfo') {
        let json = null;
        try {
          json = JSON.parse(data.toString());
        } catch(e) {
          console.error('Caught a malformed JSON from MQTT ("src/views/Status.vue" l.154)');
          return;
        }

        this.emitter = Object.assign({}, this.emitter, json);
      }
      if (topic === 'coopta/dryer/devices/emitter/version') {
        const version = { version: data.toString() };
        this.emitter = Object.assign({}, this.emitter, version);
      }
      if (topic === 'coopta/dryer/devices/vps616379/#') {
        console.log(topic, data.toString());
      }
      if (topic === 'coopta/dryer/devices/vps616379/cpu_percent') {
        console.log(topic, data.toString());
      }
    }
  }
}
</script>