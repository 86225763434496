<template>
  <v-banner
    color="error"
    elevation="2"
    tile
    v-model="visibility"
    app
    sticky
    style="flex: 0 0 100%; margin-top: 109px;"
    transition="scroll-y-transition"
  >
    <v-icon
      slot="icon"
      color="red lighten-5"
      size="36"
    >
      mdi-alert
    </v-icon>

    <h3 class="red--text text--lighten-5">ALARME</h3>
    <span v-for="alarm in alarms" :key="alarm.key" class="red--text text--lighten-5">
      {{alarm.name}}
    </span>

    <template v-slot:actions>
      <v-btn
        color="white"
        small
        text
        @click="forceDismiss"
      >
        Cacher
      </v-btn>
      <v-btn
        color="normal"
        :loading="loading"
        :disabled="loading"
        small
        @click="acknowledgeAlarms"
      >
        Acquitter
        <template v-slot:loader>
          <span>Envoi...</span>
        </template>
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  data: function() {
    return {
      keepHidden: false,
      lastAlarms: [],
      loading: false,
      visibility: false
    };
  },

  props: [
    'mqttData'
  ],

  computed: {
    alarms: function() {
      return this.getAlarmsList();
    }
  },

  methods: {
    getAlarmsList: function() {
      // no alarm: hide banner
      if (typeof this.mqttData['alarms'] === 'undefined' || this.mqttData['alarms'].length === 0) {
        this.visibility = false;
        return [];
      }
      
      // alarms already displayed but hidden by the user
      if (JSON.stringify(this.mqttData['alarms']) === this.lastAlarms && this.keepHidden) {
        this.visibility = false;
        return [];
      }

      // store last alarms
      this.lastAlarms = JSON.stringify(this.mqttData['alarms']);

      // show banner and its content
      this.visibility = true;
      return this.mqttData['alarms'];
    },

    acknowledgeAlarms: function() {
      this.loading = true;
      console.log('mqtt-publish: acknowledge alarms');

      // @todo registers must be changed (modbus commands) by masking their previous values, ie. if 124 is 37 and we need to ack_alarms: bit for 4,
      // ie. we must change the 3rd bit to 0
      // ex. => 0010 0101 (37) [alarms acknowledgeable on] => 0010 0001 (33) [ack alarms]
      if (typeof this.mqttData['40124'] === 'undefined') { // @todo should wait for a value (promise)
        return false;
      }

      const currentVal = this.mqttData['40124'].value;
      const newVal = currentVal ^ 4; // mask: 0b00000100
      console.log('Register #124 was:', currentVal, ', now it will be set to:', newVal);
      console.log('Message we will send: 124=>', newVal);

      // send command via MQTT
      this.$store.dispatch('mqttPublish', {
        topic: 'coopta/dryer/commands/register',
        msg: '124=>' + newVal,
        cb: function(error) { console.log(error); }
      }).then(function() {
        console.log('alarms acknowledged successfully');
        this.loading = false;
      }).catch(function(error) {
        // @todo maintain banner display
        console.log(error);
      });
    },

    forceDismiss: function() {
      this.keepHidden = true;
      this.visibility = false;
    }
  }
};
</script>