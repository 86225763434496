<template>
  <v-card elevation="2" outlined tile max-width="600">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <h4>Plages de temps absolues</h4>
          <!-- FROM / prepend-icon="mdi-calendar-start" -->
          <v-text-field
            type="datetime-local"
            v-model="internFrom"
            label="Du"
            step="1"
          />
          <!-- TO / prepend-icon="mdi-calendar-end" -->
          <v-text-field
            type="datetime-local"
            v-model="internTo"
            label="Au"
            step="1"
          />
          <!-- BUTTON APPLY -->
          <v-btn @click.stop="changeTimeRange()"
            color="primary"
            small>Appliquer la plage de temps</v-btn>
        </v-col>
        <v-divider vertical class="sm-1"></v-divider>
        <v-col cols="12" sm="5">
          <h4>Plages de temps relatives</h4>
          <!-- RELATIVE RANGES -->
          <v-list class="rel-time-list" dense>
            <v-list-item @click.stop="changeTimeRange(5*60)" link>Les 5 dernières minutes</v-list-item>
            <v-list-item @click.stop="changeTimeRange(15*60)" link>Les 15 dernières minutes</v-list-item>
            <v-list-item @click.stop="changeTimeRange(30*60)" link>Les 30 dernières minutes</v-list-item>
            <v-list-item @click.stop="changeTimeRange(3600)" link>La dernière heure</v-list-item>
            <v-list-item @click.stop="changeTimeRange(6*3600)" link>Les 6 dernières heures</v-list-item>
            <v-list-item @click.stop="changeTimeRange(12*3600)" link>Les 12 dernières heures</v-list-item>
            <v-list-item @click.stop="changeTimeRange(86400)" link>Les 24 dernières heures</v-list-item>
            <v-list-item @click.stop="changeTimeRange(3*86400)" link>Les 3 derniers jours</v-list-item>
            <v-list-item @click.stop="changeTimeRange(7*86400)" link>Les 7 derniers jours</v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-toolbar dense flat tile><!-- flat -->
      <p class="ma-0 text-body-2" style="color: #464c54;">Heure du navigateur
        <span class="text-caption" style="color: #7b8087;">&nbsp;France, CET</span>
      </p>

      <v-spacer></v-spacer>

      <v-chip class="text-caption"
        color="#f1f5f9"
        label
        small
        text-color="#464c54">UTC+01:00
      </v-chip>
      <v-btn class="text-caption ml-2"
        style="border: 1px solid #2c3235; font-color: #2c3235;"
        color="#e7eaf0"
        elevation="0"
        tile
        x-small>Fuseau horaire
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import datetime from '@/utils/date';

export default {
  name: 'chart-time-range-card',

  data: function() {
    return {
      internFrom: this.$store.getters.getRangeDatetimeLocal.from,
      internTo: this.$store.getters.getRangeDatetimeLocal.to
    };
  },

  computed: {
    range: function() {
      return this.$store.getters.getRange;
    }
  },

  watch: {
    range: function(val) {
      this.internFrom = datetime.formatToDatetimeLocal(val.from);
      this.internTo = datetime.formatToDatetimeLocal(val.to);
      // @notes this.internFrom and this.internTo format
      // they must be converted to local datetime without timezone specifier
      // ie. this.internFrom = '2020-03-02T14:26:31'; // GMT+1 or GMT+2 (DST)
      // ie. this.internFrom = '2020-03-02T14:26:31.015'; // GMT+1 or GMT+2 (DST) with 15 milliseconds
      //const date = new Date(val.from);
      //const timeZone = 'Europe/Berlin';
      //const zonedDate = utcToZonedTime(date, timeZone);

      //const pattern = 'd.M.yyyy HH:mm:ss.SSS \'GMT\' XXX (z)';
      //this.internFrom = format(zonedDate, pattern, { timeZone: 'Europe/Berlin' });

      console.log('component:trc:range:', val);
    }
  },

  methods: {
    changeTimeRange: function(time) {
      // @notes range.from and range.to format
      // they must be converted to UTC (or with timezone specifier)
      // ie. range.from = '2020-03-02T14:26:31.000Z'; // UTC
      // ie. range.from = '2020-03-02T14:26:31.000+00:00'; // UTC
      // ie. range.from = '2020-03-02T14:26:31.000+01:00'; // GMT+1
      let fromObj = new Date(this.internFrom);
      let toObj = new Date(this.internTo);

      // use relative time range
      if (typeof time !== 'undefined') {
        fromObj = new Date(Date.now() - time * 1000); // time in seconds
        toObj = new Date(); // now
      }

      // update store state
      this.$store.dispatch('updateRange', {
        from: fromObj.toISOString(),
        to: toObj.toISOString()
      });

      // force close menu
      this.$emit('update:success', false);
    }
  }
}
</script>

<style scoped>
.v-list-item--dense.rel-time-list, .v-list--dense.rel-time-list .v-list-item {
  min-height: 25px;
}
</style>