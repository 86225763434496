<template>
  <v-snackbar
    v-model="loading"
    bottom
    right
    timeout="2500"
    :tile="$vuetify.breakpoint.xsOnly"
    class="app-snackbar"
  >
    {{content}}
    <template v-slot:action="{ attrs }">
      <v-btn
        @click="loading = false"
        color="blue"
        text
        v-bind="attrs"
      >
        Fermer
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    loading: {
      get() {
        return this.$store.getters.snackbarState.show;
      },
      set(v) {
        return this.$store.commit('setSnackbarVisibility', v);
      }
    },
    content: function() {
      return this.$store.getters.snackbarState.content;
    }
  }
};
</script>

<style>
@media only screen and (max-width: 599px) {
  .app-snackbar > div {
    /*border-radius: 0; @see :tile="$vuetify.breakpoint.xsOnly"*/
    margin: 0;
    width: 100%;
  }
}
</style>