import http from '@/utils/http';

class RecordDataService {
  getAll(data) {
    return http.get('/records', { params: data });
  }

  get(id) {
    return http.get(`/records/${id}`);
  }

  create(data) {
    return http.post('/records', data);
  }

  update(id, data) {
    return http.put(`/records/${id}`, data);
  }

  delete(id) {
    return http.delete(`/records/${id}`);
  }

  deleteAll() {
    return http.delete('/records');
  }

  findByName(name) {
    return http.get(`/records?name=${name}`);
  }
}

export default new RecordDataService();