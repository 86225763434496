import http from '@/utils/http';

class NotificationDataService {
  getAll(data) {
    return http.get('/notifications', { params: data });
  }

  get(id) {
    return http.get(`/notifications/${id}`);
  }

  create(data) {
    return http.post('/notifications', data);
  }

  update(id, data) {
    return http.put(`/notifications/${id}`, data);
  }

  delete(id) {
    return http.delete(`/notifications/${id}`);
  }

  deleteAll() {
    return http.delete('/notifications');
  }

  findByName(name) {
    return http.get(`/notifications?name=${name}`);
  }
}

export default new NotificationDataService();