<template>
  <v-data-table :headers="headers" :items="dataTransform" item-key="id" class="elevation-1">
    <template v-slot:[`item.value`]="{ item }">
      <v-chip :color="getColor(item.value)" dark>
        {{ item.value }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: [
    'mqttData'
  ],

  data() {
    return {
      headers: [
        {
          text: 'Variable',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Valeur', value: 'value' },
        { text: 'Adresse du registre', value: 'id' },
      ]
    };
  },

  computed: {
    dataTransform: function() {
      // @todo table items could be unordered!
      /*const ordered = {};
      Object.keys(this.mqttData).sort().forEach(function(key) {
        ordered[key] = this.mqttData[key];
      });*/
      var arr = [];

      for (const key in this.mqttData) {
        arr[key] = this.mqttData[key];
        arr[key].id = key;
      }

      return arr;
    }
  },
  methods: {
    getColor(temperature) {
      if (temperature > 65) return 'red';
      else if (temperature > 60) return 'orange';
      else return 'green';
    }
  }
};
</script>