import AuthDataService from '@/services/AuthDataService';
import UserDataService from '@/services/UserDataService';
import Vue from 'vue';

const state = {
  profile: {},
  status: ''
};

const getters = {
  getUserProfile: state => state.profile,
  isUserProfileLoaded: state => !!state.profile.name
};

const actions = {
  userRequest: ({ commit, dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_USER_STATUS', 'loading');

      // returns cached profile data if exists
      let profile = getters.getUserProfile;
      console.log('user profile:', profile);
      if (Object.keys(profile).length !== 0) { // is not empty?
        console.log('not empty profile!');
        commit('SET_USER_PROFILE', profile);
        commit('SET_USER_STATUS', 'success');
        resolve(profile);
        return;
      }

      // no cached profile data, so request to the server
      AuthDataService.getCurrentUser()
        .then((response) => {
          console.log(response.data);
          commit('SET_USER_PROFILE', response.data.user);
          commit('SET_USER_STATUS', 'success');
          resolve(response.data.user);
        })
        .catch((err) => {
          console.log('USER.JS', err);
          commit('SET_USER_STATUS', 'error');
          // if response is unauthorized, logout
          if (process.env.NODE_ENV === 'production') {
            dispatch('logoutRequest'); // @see auth module
            reject(err);
          } else {
            resolve({
              mqtt: JSON.parse(localStorage.getItem('mqtt'))
            });
          }
        });
    });
  },

  updateUserProfileRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      //commit('SET_AUTH_STATUS', 'loading');

      const userId = data.id;
      delete data.id;

      UserDataService.updateUserProfile(userId, data)
        .then(function(response) {
          console.log(response.data);
          //commit('SET_AUTH_STATUS', 'success');
          // force profile reloading
          commit('SET_USER_PROFILE', {});
          dispatch('userRequest');
          resolve(response);
        })
        .catch(function(err) {
          console.log(err);
          console.log(err.response.data.message);
          //commit('SET_AUTH_STATUS', 'error');
          reject(err);
        });
    });
  }
};

const mutations = {
  SET_USER_PROFILE: (state, data) => {
    Vue.set(state, 'profile', data);
  },
  SET_USER_STATUS: (state, status) => {
    state.status = status;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};