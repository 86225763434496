<template>
  <v-container fluid>
    <h3>Etat des brûleurs</h3>

    <v-alert
      border="left"
      outlined
      type="warning"
    >En construction : les valeurs sont affichées mais ne peuvent pas être définies pour le moment.</v-alert>

    <v-row>
      <v-col cols="6">

        <v-card
          class="mx-auto"
          max-width="300"
          tile
        >
          <v-list disabled>
            <v-subheader>Brûleur inférieur</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-fire</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{bottomBurnerTemp.value + '°C'}} / {{bottomBurnerTempTarget.value + '°C'}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-fire-alert</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{bottomBurnerTempMax.value + '°C'}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{bottomBurnerUsage.value + 'H'}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>

      </v-col>
      <v-col cols="6">

        <v-card
          class="mx-auto"
          max-width="300"
          tile
        >
          <v-list disabled>
            <v-subheader>Brûleur supérieur</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-fire</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{topBurnerTemp.value + '°C'}} / {{topBurnerTempTarget.value + '°C'}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-fire-alert</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{topBurnerTempMax.value + '°C'}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{topBurnerUsage.value + 'H'}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: 'burners-view',

  props: [
    'mqttData'
  ],

  computed: {
    bottomBurnerTemp: function() {
      if (typeof this.mqttData['40300'] !== 'undefined') return this.mqttData['40300'];
      return {
        value: 0,
        name: ''
      };
    },
    bottomBurnerTempTarget: function() {
      if (typeof this.mqttData['40310'] !== 'undefined') return this.mqttData['40310'];
      return {
        value: 0,
        name: ''
      };
    },
    bottomBurnerTempMax: function() {
      if (typeof this.mqttData['40318'] !== 'undefined') return this.mqttData['40318'];
      return {
        value: 0,
        name: ''
      };
    },
    bottomBurnerUsage: function() {
      if (typeof this.mqttData['40316'] !== 'undefined') return this.mqttData['40316'];
      return {
        value: 0,
        name: ''
      };
    },
    topBurnerTemp: function() {
      if (typeof this.mqttData['40301'] !== 'undefined') return this.mqttData['40301'];
      return {
        value: 0,
        name: ''
      };
    },
    topBurnerTempTarget: function() {
      if (typeof this.mqttData['40311'] !== 'undefined') return this.mqttData['40311'];
      return {
        value: 0,
        name: ''
      };
    },
    topBurnerTempMax: function() {
      if (typeof this.mqttData['40319'] !== 'undefined') return this.mqttData['40319'];
      return {
        value: 0,
        name: ''
      };
    },
    topBurnerUsage: function() {
      if (typeof this.mqttData['40317'] !== 'undefined') return this.mqttData['40317'];
      return {
        value: 0,
        name: ''
      };
    }
  }
}
</script>