<template>
  <v-alert
    border="left"
    colored-border
    :type="type"
    v-model="alert"
    elevation="2"
    dismissible
  >
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  props: [
    "id",
    "type"
  ],
  data: function() {
    return {
      alert: localStorage.getItem('displayAlert' + this.id) !== "false"
    }
  },
  watch: {
    alert: function() {
      localStorage.setItem('displayAlert' + this.id, "false");
    }
  }
};
</script>