import mqtt from 'mqtt';
import Vue from 'vue';

const state = {
  client: null,
  status: ''
};

var promiseCache = null; // connecting promise

const getters = {
  getMqttClient: state => state.client
};

const actions = {
  connectMqtt: function({ commit/*, getters*/ }) {
    // run connect promise only once
    if (promiseCache) {
      return promiseCache;
    }

    promiseCache = new Promise((resolve/*, reject*/) => {
      commit('SET_MQTT_STATUS', 'loading');

      // client already defined
      /*let storedClient = getters.getMqttClient;
      if (storedClient) {
        commit('SET_MQTT_STATUS', 'success');
        resolve(storedClient);
        return;
      }*/

      // wait for user authentication (need its credentials) and return mqtt client
      // @todo beforeDestroy() {this.unsubscribe();}
      /*this.unsubscribe =*/ this.subscribe((mutation) => {
        if (mutation.type === 'SET_AUTH_MQTT') {
          let credentials = mutation.payload;
          if (credentials === null) {
            // user logout
            // @todo should disconnect mqtt client!
            return;
          }

          console.log('login in mqtt broker with credentials:', credentials);

          // MQTT.js connect()
          let client = mqtt.connect(
            credentials.host,
            {
              clientId: 'coopta-dryer-web-' + parseInt(Math.random() * 100000),
              username: credentials.user,
              password: credentials.pass
            }
          );

          // @todo try...catch
          //reject();

          commit('SET_MQTT_CLIENT', client);
          commit('SET_MQTT_STATUS', 'success');
          resolve(client);
        }
      });
    });

    return promiseCache;
  },

  mqttSubscribe: function({ commit, dispatch }, topic) {
    return new Promise((resolve, reject) => {
      commit('SET_MQTT_STATUS', 'loading');

      // ensure client is already defined
      dispatch('connectMqtt')
        .then(function(client) {
          client.subscribe(topic, function(error, granted) {
            if (error) {
              console.log(error);
              commit('SET_MQTT_STATUS', 'error');
              reject(error);
            } else {
              commit('SET_MQTT_STATUS', 'success');
              resolve(granted);
            }
          });
        });
    });
  },

  mqttUnsubscribe: function({ commit, dispatch }, topic) {
    return new Promise((resolve, reject) => {
      commit('SET_MQTT_STATUS', 'loading');

      // ensure client is already defined
      dispatch('connectMqtt')
        .then(function(client) {
          client.unsubscribe(topic, function(error) {
            if (error) {
              console.log(error);
              commit('SET_MQTT_STATUS', 'error');
              reject(error);
            } else {
              commit('SET_MQTT_STATUS', 'success');
              resolve();
            }
          });
        });
    });
  },

  mqttPublish: function({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_MQTT_STATUS', 'loading');

      // ensure client is already defined
      dispatch('connectMqtt')
        .then(function(client) {
          client.publish(data.topic, data.msg, function(error) {
            if (error) {
              console.log(error);
              data.cb(error);
              commit('SET_MQTT_STATUS', 'error');
              reject(error);
            } else {
              commit('SET_MQTT_STATUS', 'success');
              resolve();
            }
          });
        });
    });
  },

  mqttOnMessage: function({ dispatch }, callback) {
    // ensure client is already defined
    dispatch('connectMqtt')
      .then(function(client) {
        client.on('message', function(data, topic) {
          callback(data, topic);
        });
      });
  }
};

const mutations = {
  SET_MQTT_CLIENT: (state, handle) => {
    Vue.set(state, 'client', handle);
  },
  SET_MQTT_STATUS: (state, status) => {
    state.status = status;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};