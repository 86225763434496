<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="300"
      offset-y
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar v-if="getProfile.username" color="primary" size="36">
            <span class="white--text text-subtitle-2">{{ getUserInitials }}</span>
          </v-avatar>
          <v-avatar v-else color="primary" size="36">
            <v-icon dark><!-- mdi-account-circle -->
              mdi-login-variant
            </v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar v-if="getProfile.username" color="primary" style="padding-left: 11px;">
              <!--<v-img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"></v-img>-->
              <span class="white--text text-subtitle-2">{{ getUserInitials }}</span>
            </v-list-item-avatar>

            <v-list-item-content v-if="getProfile.username">
              <v-list-item-title>{{ getProfile.username }}</v-list-item-title>
              <v-list-item-subtitle>{{ getProfile.email }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>Déconnecté</v-list-item-title>
            </v-list-item-content>

            <!--<v-list-item-action>
              <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                @click="fav = !fav"
              >
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            </v-list-item-action>-->
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item-group color="primary">
            <v-list-item v-if="isAuthenticated" @click="profile">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Profil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="isAuthenticated" @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Se déconnecter</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="!isAuthenticated" @click="login">
              <v-list-item-icon>
                <v-icon>mdi-login</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Se connecter</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="!isAuthenticated" @click="register">
              <v-list-item-icon>
                <v-icon>mdi-account-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>S'enregistrer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="menu = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      fav: true,
      menu: false
    };
  },

  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    },
    getProfile: function() {
      return this.$store.getters.getUserProfile;
    },
    getUserInitials: function() {
      let initials = this.$store.getters.getUserProfile.username;
      if (initials) return initials.substring(0, 2).toUpperCase();
      return 'NO';
    }
  },

  methods: {
    login: function() {
      // if we're not already on the login page
      if (this.$route.path != '/login') {
        this.$router.push('/login');
      }
      this.menu = false; // close menu
    },
    logout: function() {
      this.$store.dispatch('logoutRequest').then(() => {
        this.$router.push('/login'); // @todo go to previous page or main page
      });
      this.menu = false; // close menu
    },
    profile: function() {
      // if we're not already on the profile page
      if (this.$route.path != '/profile') {
        this.$router.push('/profile');
      }
      this.menu = false; // close menu
    },
    register: function() {
      // if we're not already on the register page
      if (this.$route.path != '/register') {
        this.$router.push('/register');
      }
      this.menu = false; // close menu
    }
  }
};
</script>