<template>
  <SensorsTable :mqttData="mqttData" />
</template>

<script>
import SensorsTable from '@/components/SensorsTable';

export default {
  name: 'sensors',

  components: {
    SensorsTable
  },

  props: [
    'mqttData'
  ]
};
</script>