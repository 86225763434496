<template>
  <v-progress-linear
    :active="loading"
    color="blue"
    indeterminate
  ></v-progress-linear>
</template>

<script>
export default {
  computed: {
    loading: function() {
      return this.$store.getters.loadingState;
    }
  }
};
</script>

<style scoped>
/* @todo check https://medium.com/js-dojo/how-to-visualize-application-loading-state-in-vuetify-44f0f0242094 */
.v-progress-linear {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
</style>