<template>
  <v-container>
    <h3>Paramètrages des notifications</h3>

    <Alert id="info1" type="info">
      Chaque notification peut utiliser un canal particulier (SMS, appel, email,
      push etc...) et avoir une portée définie (alarmes, dépassement de
      température, défaut mineur etc...). Elles peuvent être activées/désactivées
      temporairement.
    </Alert>

    <v-data-table
      :headers="computedHeaders"
      :items="notifications"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100, -1]
      }"
      :items-per-page="50"
      multi-sort
      class="notifications elevation-1"
    >

      <template v-slot:[`item.channel`]="{ item }">
        <span>{{ channels.find(element => element.id === item.channel).name }}</span>
      </template>

      <template v-slot:[`item.recipient_id`]="{ item }">
        <span>{{ getRecipientName(item.recipient_id) }}</span>
      </template>

      <template v-slot:[`item.variable_id`]="{ item }">
        <span>{{ variables.find(element => element.id === item.variable_id).name }}</span>
      </template>

      <template v-slot:[`item.comparison`]="{ item }">
        <span>{{ comparisonOperators.find(element => element.id === item.comparison).name }}</span>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-switch v-model="item.active" @change="checkboxChange(item)"></v-switch>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Notifications</v-toolbar-title>
          <v-divider
            v-if="$vuetify.breakpoint.smAndUp"
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <HelpDialog/>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click.stop="createItem()"
          >
            Ajouter
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Recharger
        </v-btn>
      </template>

    </v-data-table>

    <!-- create/edit dialog -->
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500px"
    >
      <v-card>
        <v-toolbar
          v-if="$vuetify.breakpoint.xsOnly"
          color="primary"
          style="margin-bottom: 20px;"
        >
          <v-btn
            color="white"
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            color="white"
            text
            @click="save"
          >
            Enregistrer
          </v-btn>
        </v-toolbar>

        <v-card-title v-if="$vuetify.breakpoint.smAndUp">
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  label="Nom"
                  autofocus
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  v-model="editedItem.description"
                  rows="2"
                  auto-grow
                  clearable
                  counter
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-select
                  :items="variables"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.variable_id"
                  label="Variable"
                  dense
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="comparisonOperators"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.comparison"
                  label="Comparaison"
                  dense
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.reference"
                  type="number"
                  label="Référence"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6">
                <v-select
                  :items="channels"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.channel"
                  label="Canal"
                  dense
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select v-if="recipients"
                  :items="recipients"
                  item-text="username"
                  item-value="id"
                  v-model="editedItem.recipient_id"
                  label="Destinataire"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea
                  label="Modèle"
                  v-model="editedItem.template"
                  rows="3"
                  auto-grow
                  clearable
                  counter
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="7">
                <v-text-field
                  v-model="editedItem.wait"
                  type="number"
                  step="1"
                  min="10"
                  max="86400"
                  label="Intervalle"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="d-flex justify-center">
                <v-switch
                  v-model="editedItem.active"
                  label="Activation"
                  hide-details
                  class="mt-0"
                  dense
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

        <v-card-actions v-if="$vuetify.breakpoint.smAndUp">
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="close"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog
      v-model="dialogDelete"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">Etes-vous sûr de vouloir supprimer cette notification ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import Alert from '@/components/Alert';
import HelpDialog from '@/components/NotificationsTable/HelpDialog';
import NotificationDataService from '@/services/NotificationDataService';
import UserDataService from '@/services/UserDataService';

export default {
  components: {
    Alert,
    HelpDialog
  },

  data: function() {
    return {
      page: 0,
      totalItems: 0,
      totalPages: 0,
      sortBy: 'id',
      sortDesc: false,
      locale: 'fr-FR',
      notifications: [],
      loading: true,
      options: {},

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID', value: 'id' },
        {
          text: 'Nom',
          align: 'start',
          sortable: false,
          value: 'name',
          mobile: true
        },
        //{ text: 'Description', value: 'description' },
        { text: 'Canal', value: 'channel', mobile: true },
        { text: 'Destinataire', value: 'recipient_id', mobile: true },
        { text: 'Variable', value: 'variable_id' },
        { text: 'Comparaison', value: 'comparison', sortable: false },
        { text: 'Référence', value: 'reference', sortable: false },
        { text: 'Activée', value: 'active', sortable: false, mobile: true },
        { text: 'Actions', value: 'actions', sortable: false, mobile: true }
      ],
      channels: [ // get from api?
        { id: 1, name: 'Email' },
        { id: 2, name: 'SMS' },
        { id: 3, name: 'Push' },
        { id: 4, name: 'Webhook' },
        { id: 5, name: 'Signal' }
      ],
      comparisonOperators: [ // start at 1?
        { id: 0, name: 'supérieur à' },
        { id: 1, name: 'inférieur à' },
        { id: 2, name: 'supérieur ou égal à' },
        { id: 3, name: 'inférieur ou égal à' },
        { id: 4, name: 'égal à' },
        { id: 5, name: 'différent de' }
      ],
      recipients: [ // @todo get from api
        /*{ id: 1, name: 'Admin' },
        { id: 2, name: 'David' },
        { id: 3, name: 'Benjamin' }*/
      ],
      variables: [
        { id: 1, name: 'Défauts majeurs' },
        { id: 2, name: 'Sondes premier seuil' },
        { id: 3, name: 'Sondes second seuil' },
        { id: 40300, name: 'Température brûleur inférieur' },
        { id: 40301, name: 'Température brûleur supérieur' },
        { id: 40302, name: 'Température grain' },
        { id: 40306, name: 'Consigne minuterie calculée' },
        { id: 40307, name: 'Décompte intervalle extraction' },
        { id: 40309, name: 'Décompte ventilation retardée' },
        { id: 40316, name: 'Durée fonctionnement brûleur inférieur' },
        { id: 40317, name: 'Durée fonctionnement brûleur supérieur' },
        { id: 40328, name: 'Compteur extractions total' },
        { id: 40315, name: 'Compteur extractions journalier' },
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        name: '',
        description: '',
        channel: 0,
        recipient_id: 0,
        template: '',
        variable_id: 0,
        comparison: 0,
        reference: 0,
        wait: 900,
        user_id: 0,
        active: true
      },
      defaultItem: {
        id: 0,
        name: '',
        description: '',
        channel: 0,
        recipient_id: 0,
        template: '',
        variable_id: 0,
        comparison: 0,
        reference: 0,
        wait: 900,
        user_id: 0,
        active: true
      }
    };
  },

  computed: {
    computedHeaders: function() {
      // not mobile: display all headers
      if (this.$vuetify.breakpoint.smAndUp) {
        return this.headers;
      }

      // mobile: display a subset of headers (these with property "mobile" set to true)
      return this.headers.filter(function(header) {
        if (typeof header.mobile === 'undefined') return false;
        return header.mobile;
      });  
    },
    formTitle: function() {
      return this.editedIndex === -1 ? 'Ajouter' : 'Modifier';
    },
    // @todo B
    isFabClicked: function() {
      return this.$store.getters['fab/isClicked'];
    }
  },

  watch: {
    isFabClicked: function(action) {
      // prevent incorrect fab action
      if (action === 'create-user-notification') {
        // reset click state
        this.$store.dispatch('fab/click', false);

        // call linked method
        this.createItem();
      }
    },
    dialog: function(visible) {
      visible || this.close();
    },
    dialogDelete: function(visible) {
      visible || this.closeDelete();
    },
    options: {
      handler: function() {
        //this.readDataFromAPI()
      }
    },
    deep: true
  },

  // this will trigger in the onReady State
  mounted: function() {
    this.initialize();

    // set fab context
    this.$store.dispatch('fab/set', {
      action: 'create-user-notification',
      color: 'primary',
      icon: 'mdi-plus'
    });
  },

  methods: {
    initialize: function() {
      this.readDataFromAPI(); // read notifications

      // get user list
      const data = {
        limit: -1, // @todo api should return an unlimited list of users
        offset: 0,
        sort: '+username'
      }

      // @todo it would fail on slow networks: this.recipients won't be defined on render
      UserDataService.getAll(data)
        .then((response) => {
          // then injecting the result
          this.recipients = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getRecipientName: function(id) {
      const recipient = this.recipients.find(element => element.id === id);
      let name = '';
      if (recipient) {
        name = recipient.username;
      }
      return name;
    },

    createItem: function() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },

    editItem: function(item) {
      this.editedIndex = this.notifications.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem: function(item) {
      this.editedIndex = this.notifications.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm: function() {
      // @todo
      const id = this.notifications[this.editedIndex].id;

      NotificationDataService.delete(id)
        .then((response) => {
          console.log(response.data);
          this.notifications.splice(this.editedIndex, 1);
          this.closeDelete();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    close: function() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete: function() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    checkboxChange: function(item) {
      this.editedIndex = this.notifications.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.save();
    },

    save: function() {
      const data = this.editedItem;

      if (this.editedIndex > -1) {
        // @todo notification id is in this.editedItem ?
        const id = this.notifications[this.editedIndex].id;
        NotificationDataService.update(id, data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            console.log(response.data);
            Object.assign(this.notifications[this.editedIndex], this.editedItem);
            this.close();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        NotificationDataService.create(data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            console.log(response.data);
            this.readDataFromAPI();
            this.close();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    // reading data from API method.
    readDataFromAPI: function() {
      this.loading = true;
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      console.log(this.options);
      const pageNumber = page - 1;
      const offset = pageNumber * itemsPerPage;

      var sortQuery = '';
      if (sortBy.length > 0) {
        // qsort=+author,-datepublished
        var sortOrderSymbol = ''
        for (var i = 0; i < sortBy.length; i++) {
          sortOrderSymbol = sortDesc[i] ? '-' : '+';
          sortQuery += sortOrderSymbol + sortBy[i] + ',';
        }
        sortQuery = sortQuery.slice(0, -1);
      }

      let data = {
        limit: itemsPerPage,
        page: pageNumber,
        offset: offset
      };

      if (sortQuery) {
        data.sort = sortQuery;
      }

      NotificationDataService.getAll(data)
        .then((response) => {
          // then injecting the result to datatable parameters.
          this.loading = false;
          this.notifications = response.data;
          this.totalItems = parseInt(response.headers['x-total-count']);
          this.totalPages = parseInt(response.headers['x-total-pages']);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    toggleOrder: function() {
      if (this.sortDesc === 'asc') {
        this.sortDesc = 'desc';
      }
    },

    nextSort: function() {
      let index = this.headers.findIndex(h => h.value === this.sortBy);
      index = (index + 1) % this.headers.length;
      this.sortBy = this.headers[index].value;
    }
  }
};
</script>

<style>
/* style row in mobile mode */
.v-data-table.notifications > .v-data-table__wrapper .v-data-table__mobile-table-row {
  margin: 10px;
  border: 1px solid #ededed;
  display: block;
}
</style>