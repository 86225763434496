<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-form @submit.prevent="submitForm" ref="form">
          <v-card width="400" class="mx-auto mt-5">
            <v-card-title class="pb-0">
              <h3>Validation de l'adresse email</h3>
            </v-card-title>
            <v-card-text>

              <v-alert v-if="error"
                v-model="error"
                border="left"
                dismissible
                type="error"
              >{{errorText}}</v-alert>

              <v-text-field
                v-model="selector"
                label="Sélecteur"
                hide-details
                class="shrink"
              ></v-text-field>

              <v-text-field
                v-model="token"
                label="Code"
                hide-details
                class="shrink"
              ></v-text-field>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="goLoginPage" color="primary" text>
                Se connecter
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="success">
                Vérifier
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      error: false,
      errorText: '',
      selector: '',
      token: ''
    };
  },

  mounted: function() {
    // get selector and token from the query string
    let selector, token = ''; // @todo this.selector/this.token from data would work ?
    if (this.$route.query.selector) {
      selector = this.$route.query.selector;
    }
    if (this.$route.query.token) {
      token = this.$route.query.token;
    }

    // automatically call the api to validate email
    // @todo so the form in the template has no use at all...
    //       but alert is used to show error from server!
    this.confirmEmail(selector, token);
  },

  methods: {
    submitForm: function() {
      //this.$refs.form.validate();
      let { selector, token } = this;
      
      this.confirmEmail(selector, token);
    },
    confirmEmail: function(selector, token) {
      // initialize error alert
      this.error = false;

      const self = this;

      this.$store.dispatch('confirmEmailRequest', { selector, token })
        .then(function() {
          self.$router.push('/');
        })
        .catch(function(error) {
          self.errorText = error.message;
          // get server response
          if (error.response.data && error.response.data.message) {
            self.errorText = error.response.data.message;
          }
          self.error = true;
        });
    },
    goLoginPage: function() {
      this.$router.push('/login');
    }
  }
};
</script>