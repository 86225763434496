<template>
  <v-main>
    <Banner :mqttData="mqttData" v-show="isNotHomePage && isAuthenticated" />
    <router-view :mqttData="mqttData"></router-view>
  </v-main>
</template>

<script>
import Banner from '@/components/Banner';

export default {
  name: 'main-container',

  components: {
    Banner
  },

  props: [
    'mqttData'
  ],

  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    },
    isNotHomePage: function() {
      return this.$route.name !== 'home';
    }
  }
};
</script>