import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import chart from './modules/chart';
import fab from './modules/fab';
import mqtt from './modules/mqtt';
import notifications from './modules/notifications';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  // @todo try to use strict mode later...
  // disabled (false) in production
  //strict: process.env.NODE_ENV !== 'production',

  state: {
    banner: {
      show: true
    },
    drawerState: false,
    loadingState: false,
    snackbar: {
      show: false,
      content: 'Default text'
    }
  },

  getters : {
    bannerShowState(state) {
      return state.banner.show;
    },
    drawerState(state) {
      return state.drawerState;
    },
    loadingState(state) {
      return state.loadingState;
    },
    snackbarState(state) {
      return state.snackbar;
    }
  },

  actions: {

  },

  mutations: {
    setBannerState(state, data) {
      state.banner.show = data;
    },
    setLoadingState(state, data) {
      state.loadingState = data;
    },
    setSnackbarVisibility(state, data) {
      state.snackbar.show = data;
    },
    toggleDrawerState(state, data) {
      state.drawerState = data;
    }
  },

  modules: {
    auth,
    chart,
    fab,
    mqtt,
    notifications,
    user
  }
});