<template>
  <v-container v-if="mqttData.timer"><!-- @todo prevent undefined error, should wait for mqtt connect -->
    <h3>Tests</h3>

    <v-alert
      border="left"
      outlined
      type="warning"
    >En construction : test.</v-alert>


<!--<template>
  <v-timeline >
    <v-timeline-item small>
      <span slot="opposite" class="headline font-weight-bold blue--text">2020-11-08 00:54:32</span>
      <v-card class="elevation-2">
        <v-card-title class="headline">
          Lorem ipsum
        </v-card-title>
        <v-card-text>
          Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item small>
      <v-card class="elevation-2">
        <v-card-title class="headline">
          Lorem ipsum
        </v-card-title>
        <v-card-text>
          Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item small>
      <v-card class="elevation-2">
        <v-card-title class="headline">
          Lorem ipsum
        </v-card-title>
        <v-card-text>
          Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>-->

    <h5>Etats divers (registre 40110)</h5>
    Type minuterie : {{mqttData.timer.value ? 'modulée' : 'fixe'}}<br>
    Transporteur vidange : {{mqttData.reddler.value ? 'marche' : 'arrêt'}}<br>
    Niveau bas réserve : {{mqttData.lowLevel.value ? 'vide' : 'présence grain'}}<br>
    Niveau haut réserve : {{mqttData.highLevel.value ? 'plein' : 'pas de grain'}}<br><br>

    Volet extraction : {{mqttData.extractFlap.value ? 'ouvert' : 'fermé'}}<br>
    Grille extraction (tous) : {{mqttData.extractAll.value ? 'ouvert' : 'fermé'}}<br>
    Grille extraction : {{mqttData.extractLeft.value ? 'ouvert' : 'fermé'}}<br>
    Evacuation trémie (tous) : {{mqttData.drainAll.value}}<br>
    Evacuation trémie : {{mqttData.drainLeft.value}}<br>
    Volet extraction (rouge) : {{mqttData.extractFlapRed.value}}<br>
    Volet extraction (rouge bis) : {{mqttData.extractFlapRed2.value}}<br><br>

    <h5>Etats divers (registre 40100)</h5>
    Général : {{mqttData.general.value}}<br>
    Inter : {{mqttData.inter.value}}<br>
    Présence air comprimé : {{mqttData.air.value ? 'pression ok' : 'pas d\'air'}}<br>
    Porte vis : {{mqttData.door1.value ? 'fermée' : 'ouverte'}}<br>
    Porte ventilateur VT1 : {{mqttData.door2.value ? 'fermée' : 'ouverte'}}<br>
    Volets tournesol : {{mqttData.bigSideFlap.value ? 'fermé' : 'ouvert'}}<br>
    Contrôle débit air brûleur inférieur : {{mqttData.airFlowCheck.value}}<br>
    Porte filtre supérieur/porte dépoussièrage : {{mqttData.door3.value ? 'fermée' : 'ouverte'}}<br>
    Cycle refroidissement : {{mqttData.coolingState.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Cycle séchage : {{mqttData.running.value ? 'en fonctionnement' : 'arrêt'}}<br>
    En défaut : {{mqttData.defaults.value ? 'séchoir en défaut' : 'pas de défaut'}}<br><br>

    <h5>Etats vis à poussière (registre 40101)</h5>
    Vis à poussière (aspirateur) : {{mqttData.screwAspirator.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vis à poussière : {{mqttData.screwConveyor.value ? 'en fonctionnement' : 'arrêt'}}<br><br>

    <h5>Etats ventilateur air chaud (registre 40102)</h5>
    Disj : {{mqttData.disj.value ? 'en fonctionnement' : 'arrêt'}}<br>
    ATS + moteur VT1 : {{mqttData.ats.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Corps VT1 : {{mqttData.warmAirFan.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Rect VT1 : {{mqttData.vt1rect.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Carré VT1 : {{mqttData.vt1square.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Cône VT1 : {{mqttData.vt1pulse.value ? 'en fonctionnement' : 'arrêt'}}<br>
    ATS (gris) + moteur VT1 (rouge) : {{mqttData.atsMotorDefault.value}}<br>
    ATS (rouge) : {{mqttData.atsDefault.value ? 'en défaut' : 'false'}}<br>
    Corps VT1 (rouge) : {{mqttData.vt1Default.value ? 'en défaut' : 'false'}}<br>
    Carré VT1 (rouge) : {{mqttData.vt1squareDefault.value ? 'en défaut' : 'false'}}<br><br>

    <h5>Etats brûleur inférieur (registre 40111)</h5>
    Etat brûleur : {{mqttData.bBurnerRunning.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Filtre et pressostats : {{mqttData.bFilterPressostats.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne de sécurité : {{mqttData.bSecurityValve.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne pilote : {{mqttData.bPilotValve.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne by-pass : {{mqttData.bBypassValve.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne modulante : {{mqttData.bModulatingValve.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne modulante 2 : {{mqttData.bModulatingValve2.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne modulante + : {{mqttData.bModulatingValveHigh.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne modulante - : {{mqttData.bModulatingValveLow.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Mini : {{mqttData.bMini.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Arrêt d'urgence (rouge) : {{mqttData.bEmergencyStop.value}}<br>
    Boîte brûleur (rouge) : {{mqttData.bBurnerBoxRed.value}}<br>
    CE (rouge) : {{mqttData.bCeRed.value}}<br>
    Divers (rouge) : {{mqttData.bMisc.value}}<br>
    Filtre et pressostats (rouge) : {{mqttData.bFilterPressostatsRed.value}}<br>
    Synthèse défauts (rouge) : {{mqttData.bDefaultSynthesis.value}}<br><br>

    <h5>Etats brûleur supérieur (registre 40112)</h5>
    Etat brûleur : {{mqttData.tBurnerRunning.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Filtre et pressostats : {{mqttData.tFilterPressostats.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne de sécurité : {{mqttData.tSecurityValve.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne pilote : {{mqttData.tPilotValve.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne by-pass : {{mqttData.tBypassValve.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne modulante : {{mqttData.tModulatingValve.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne modulante 2 : {{mqttData.tModulatingValve2.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne modulante + : {{mqttData.tModulatingValveHigh.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Vanne modulante - : {{mqttData.tModulatingValveLow.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Mini : {{mqttData.tMini.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Arrêt d'urgence (rouge) : {{mqttData.tEmergencyStop.value}}<br>
    Boîte brûleur (rouge) : {{mqttData.tBurnerBoxRed.value}}<br>
    CE (rouge) : {{mqttData.tCeRed.value}}<br>
    Divers (rouge) : {{mqttData.tMisc.value}}<br>
    Filtre et pressostats (rouge) : {{mqttData.tFilterPressostatsRed.value}}<br>
    Synthèse défauts (rouge) : {{mqttData.tDefaultSynthesis.value}}<br><br>

    <h5>Etats ventilateur air chaud (registre 40115)</h5>
    Aspirateur droit : {{mqttData.rightAspirator.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Filtre rotatif haut/droit : {{mqttData.rotativeFilterTR.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Filtre rotatif bas/droit : {{mqttData.rotativeFilterBR.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Aspirateur gauche : {{mqttData.leftAspirator.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Filtre rotatif haut/gauche : {{mqttData.rotativeFilterTL.value ? 'en fonctionnement' : 'arrêt'}}<br>
    Filtre rotatif bas/gauche : {{mqttData.rotativeFilterBL.value ? 'en fonctionnement' : 'arrêt'}}<br><br>

    <!--<h5>Décodage registres</h5>
    40103 Animation par bit {{decode40103}} (à décoder)<br>
    40104 Animation par bit {{decode40104}} (pas utilisé ?)<br>

    40120 Animation par mot (à décoder)<br>
    40121 Animation par mot (à décoder)<br>
    40122 Animation par mot (à décoder)<br>
    40123 Animation par mot (à décoder)<br>
    40124 Animation par mot (à décoder)<br>
    40125 Animation par mot (à décoder)<br>
    40126 Animation par mot (à décoder)<br>
    40127 Animation par mot (à décoder)<br>
    40128 Animation par mot (à décoder)<br>
    40129 Animation par mot (à décoder)<br><br>

    40130 Défauts séchoir (décodage dans access)<br>
    40131 Défauts séchoir (décodage dans access)<br>
    40132 Défauts séchoir (décodage dans access)<br>
    40133 Défauts séchoir (décodage dans access)<br>
    40134 Défauts séchoir (décodage dans access)<br>
    40135 Défauts séchoir (décodage dans access)<br>
    40136 Défauts séchoir (décodage dans access)<br>
    40137 Défauts séchoir (décodage dans access)<br><br>

    40150 Défauts 1er seuil (décodage dans access)<br>
    40151 Défauts 1er seuil (décodage dans access)<br>
    40152 Défauts 1er seuil (décodage dans access)<br>
    40153 Défauts 1er seuil (décodage dans access)<br>
    40154 Défauts 2éme seuil (décodage dans access)<br>
    40155 Défauts 2éme seuil (décodage dans access)<br>
    40156 Défauts 2éme seuil (décodage dans access)<br>
    40157 Défauts 2éme seuil (décodage dans access)<br><br>

    40162 Inhibition sondes 1-8 (ok)<br>
    40163 Inhibition sondes 9-16 (ok)<br>
    40164 Inhibition sondes (pas utilisé)<br>
    40165 Inhibition sondes (pas utilisé)<br><br>-->

    <h5>Commandes</h5>
    <pre>
    40121 0 32 128 Effacement des alarmes en cours ?

    40122 0    Passage en minuterie modulée (nb: probablement pas 0 mais !10, cf. ci-dessous)
    40122 10   Passage en minuterie fixe
    40122 1024 Arrêt immédiat (met le séchoir en défaut, un acquittement armoire est obligatoire!)
    
    40124 1  Arrêt normal + refroidissement
    40124 2  Démarrage séchoir
    40124 4  Acquittement défaut
    40124 16 Arrêt klaxon

    40310 100 Mise à jour consigne température brûleur inférieur à 100°C
    </pre>

  </v-container>
</template>

<script>
export default {
  name: 'tests',

  props: [
    'mqttData'
  ],

  computed: {
    alarms: function() {
      // there's a failure/alarm if these words are not equal to 0 !!!
      // 40130 -> 40137
      // 40192 sondes 1er seuil (syno says 40150 -> 40153...)
      // 40200 sondes 2éme seuil (syno says 40154 -> 40157...)
      var value = 0;

      if (typeof this.mqttData['40130'] !== 'undefined') {
        value = this.mqttData['40130'].value;
      }

      // @todo alarm detection: add all alarm related register values and check that the sum is 0,
      // if sum is not zero: check each register with bitmask.
      // -> alarm checking should be done on the backend and notifications shoud be pushed
      // in the frontend, so we don't do the parsing twice and we can build a persistent history
      // in database

      const MASK = {
        s1: 0b00000001, // 0x0001
        s2: 0b00000010, // 0x0002
        s3: 0b00000100, // 0x0004
        s4: 0b00001000, // 0x0008
        timer: 0b00010000, // 0x0010
        s6: 0b00100000, // 0x0020
        s7: 0b01000000, // 0x0040
        s8: 0b10000000,  // 0x0080
      };

      return {
        timer:   (value & MASK.timer) !== 0,
        //reddler: (value & MASK.reddler) !== 0,
        //level:   (value & MASK.level) !== 0
      };
    }
  }
}
</script>