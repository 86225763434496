<template>
  <v-container id="tracking-container"><!-- @todo identifier uniformisation on each pages? -->
    <h3>Suivi</h3>

    <v-alert
      border="left"
      outlined
      type="warning"
    >En construction : proof of concept.</v-alert>

    <!--<pre>
    - Switch : "Activer les rappels flottants ?"
    - Le délai entre rappels doit être paramètrable ou fixe ?
    - Les fenêtres de rappel doivent apparaitre sur toutes les pages de l'app, comme pour les alarmes
    - Edition complète ou limitée des champs ?
      ex. est-il souhaitable que la température de masse récupérée automatiquement soit modifiable ?
    - possibilité d'export en pdf/excel et impression pour archivage papier
    - graphe humidité fn(temps)
    </pre>-->

    <v-data-table
      :headers="headers"
      :items="records"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100, -1]
      }"
      :items-per-page="25"
      multi-sort
      class="elevation-1"
    >

      <template v-slot:[`item.product_id`]="{ item }">
        <span>{{ products.find(element => element.id === item.product_id).name }}</span>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Enregistrements</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            icon
            @click.stop="print()"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            @click.stop="createItem()"
          >
            Ajouter
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Recharger
        </v-btn>
      </template>

    </v-data-table>

    <!-- create/edit dialog -->
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.moisture"
                  type="number"
                  step="0.1"
                  min="5"
                  max="50"
                  label="Humidité"
                  autofocus
                  dense
                >
                  <v-icon slot="append">mdi-water-percent</v-icon>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.temperature"
                  type="number"
                  step="0.1"
                  min="0"
                  max="100"
                  label="Température"
                  dense
                >
                  <v-icon slot="append">mdi-temperature-celsius</v-icon>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="products"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.product_id"
                  label="Produit"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea
                  label="Commentaire"
                  v-model="editedItem.comment"
                  rows="2"
                  auto-grow
                  clearable
                  counter
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  :value="formatToDatetimeLocal(editedItem.measure_time)"
                  @input="updateFromDatetimeLocal($event)"
                  type="datetime-local"
                  step="1"
                  label="Date"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Annuler
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- delete dialog -->
    <v-dialog
      v-model="dialogDelete"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">Etes-vous sûr de vouloir supprimer cet enregistrement ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn fab
      @click.stop="createItem()"
      bottom
      color="primary"
      fixed
      left
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

  </v-container>
</template>

<script>
import datetime from '@/utils/date';
import RecordDataService from '@/services/RecordDataService';
import print from 'print-js';

export default {
  name: 'tracking-view',

  props: [
    'mqttData'
  ],

  data: function() {
    return {
      page: 0,
      totalItems: 0,
      totalPages: 0,
      sortBy: 'measure_time',
      sortDesc: true,
      locale: 'fr-FR',
      records: [],
      loading: true,
      options: {},

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Date', value: 'measure_time' },
        {
          text: 'Commentaire',
          align: 'start',
          sortable: false,
          value: 'comment',
          width: '28%'
        },
        { text: 'Humidité', value: 'moisture' },
        { text: 'Température', value: 'temperature' },
        { text: 'Produit', value: 'product_id' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      products: [
        { id: 1, name: 'Maïs' },
        { id: 2, name: 'Sorgho' }
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        measure_time: '',
        moisture: 0,
        temperature: 0,
        target_temp: 0,
        extractions_timer: 0,
        bottom_burner_target: 0,
        top_burner_target: 0,
        product_id: 0,
        comment: '',
        user_id: 0
      },
      defaultItem: {
        id: 0,
        measure_time: '',
        moisture: 15,
        temperature: 0,
        target_temp: 0,
        extractions_timer: 0,
        bottom_burner_target: 0,
        top_burner_target: 0,
        product_id: 0,
        comment: '',
        user_id: 0
      }
    };
  },

  computed: {
    formTitle: function() {
      return this.editedIndex === -1 ? 'Ajouter' : 'Modifier';
    }
  },

  watch: {
    dialog: function(visible) {
      visible || this.close();
    },
    dialogDelete: function(visible) {
      visible || this.closeDelete();
    },
    options: {
      handler: function() {
        //this.readDataFromAPI()
      }
    },
    deep: true
  },

  // this will trigger in the onReady State
  mounted: function() {
    this.initialize();
  },

  methods: {
    initialize: function() {
      this.readDataFromAPI();
    },

    formatToDatetimeLocal: function(date) {
      if (!date) {
        return null;
      }

      return datetime.formatToDatetimeLocal(date);
    },

    updateFromDatetimeLocal: function(date) {
      // date with "T"
      let dateArray = date.split('T');
      let dateString = dateArray[0] + ' ' + dateArray[1];
      console.log('updateFromDatetimeLocal: ', dateString);
      this.editedItem.measure_time = dateString;
    },

    print: function() {
      // @todo
      print('tracking-container', 'html');
    },

    createItem: function() {
      // get current datetime
      let defaults = {
        measure_time: datetime.currentDatetime(),
      };

      // get live temperature
      // @todo make it live and stop live update on focus/change
      if (typeof this.mqttData['40302'] !== 'undefined') {
        defaults.temperature = this.mqttData['40302'].value;
      }

      // @todo save these parameters silently?
      if (typeof this.mqttData['40305'] !== 'undefined') {
        defaults.extractions_timer = this.mqttData['40305'].value;
      }
      if (typeof this.mqttData['40310'] !== 'undefined') {
        defaults.bottom_burner_target = this.mqttData['40310'].value;
      }
      if (typeof this.mqttData['40311'] !== 'undefined') {
        defaults.top_burner_target = this.mqttData['40311'].value;
      }
      if (typeof this.mqttData['40312'] !== 'undefined') {
        defaults.target_temp = this.mqttData['40312'].value;
      }

      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem, defaults);
      this.dialog = true;
    },

    editItem: function(item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem: function(item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm: function() {
      // @todo
      const id = this.records[this.editedIndex].id;

      RecordDataService.delete(id)
        .then((response) => {
          console.log(response.data);
          this.records.splice(this.editedIndex, 1);
          this.closeDelete();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    close: function() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete: function() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save: function() {
      const data = this.editedItem;

      if (this.editedIndex > -1) {
        // @todo record id is in this.editedItem ?
        const id = this.records[this.editedIndex].id;
        RecordDataService.update(id, data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            console.log(response.data);
            Object.assign(this.records[this.editedIndex], this.editedItem);
            this.close();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        RecordDataService.create(data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            console.log(response.data);
            this.readDataFromAPI();
            this.close();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    // reading data from API method.
    readDataFromAPI: function() {
      this.loading = true;
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      console.log(this.options);
      const pageNumber = page - 1;
      const offset = pageNumber * itemsPerPage;

      var sortQuery = '';
      if (sortBy.length > 0) {
        // qsort=+author,-datepublished
        var sortOrderSymbol = '';
        for (var i = 0; i < sortBy.length; i++) {
          sortOrderSymbol = sortDesc[i] ? '-' : '+';
          sortQuery += sortOrderSymbol + sortBy[i] + ',';
        }
        sortQuery = sortQuery.slice(0, -1);
      }

      let data = {
        limit: itemsPerPage,
        page: pageNumber,
        offset: offset
      };

      if (sortQuery) {
        data.sort = sortQuery;
      }

      RecordDataService.getAll(data)
        .then((response) => {
          // then injecting the result to datatable parameters.
          this.loading = false;
          this.records = response.data;
          this.totalItems = parseInt(response.headers['x-total-count']);
          this.totalPages = parseInt(response.headers['x-total-pages']);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    toggleOrder: function() {
      if (this.sortDesc === 'asc') {
        this.sortDesc = 'desc';
      }
    },

    nextSort: function() {
      let index = this.headers.findIndex(h => h.value === this.sortBy);
      index = (index + 1) % this.headers.length;
      this.sortBy = this.headers[index].value;
    }
  }
};
</script>

<style scoped>
>>>table td:nth-child(2) {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>