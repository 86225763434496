<template>
  <v-container v-if="mqttData.timer" fluid fill-height><!-- @todo prevent undefined error, should wait for mqtt connect -->
          <Diagram
            id="dryer-main"
            :src="require('../assets/dryer-optimized.svg')"
            :style="cssVars"
          ></Diagram>

        <!-- layer switch -->
        <v-switch style="display: none;" :input-value="false" @change="toggleLayer($event, '#dlr-airflow')"
          label="Afficher le circuit d'air"></v-switch>
        <v-switch style="display: none;" :input-value="true" @change="toggleLayer($event, '#dlr-text-fields')"
          label="Afficher les valeurs"></v-switch>

  </v-container>
</template>

<script>
import Diagram from 'vue-inline-svg';

export default {
  name: 'diagram-view',

  components: {
    Diagram
  },

  props: [
    'mqttData'
  ],

  computed: {
    cssVars: function() {
      return {
        'height': '100vh',
        '--highLevel': this.mqttData.highLevel.value ? /*plein*/'inline' : /*pas de grain*/'none',
        '--lowLevel': this.mqttData.lowLevel.value ? /*vide*/'inline' : /*présence grain*/'none',

        '--compressor-color': this.mqttData.air.value ? /*marche*/'lightgreen' : /*arrêt*/'red',
        '--general-color': this.mqttData.general.value ? /*marche*/'lightgreen' : /*arrêt*/'red',
        '--inter-color': this.mqttData.inter.value ? /*marche*/'lightgreen' : /*arrêt*/'red',
        '--airflow-sensor-color': this.mqttData.airFlowCheck ? /*marche*/'lightgreen' : /*arrêt*/'red',

        '--redler': this.mqttData.reddler.value ? 'none' : 'blinkingRed linear 1s infinite',
        
        '--right-filter-aspirator-anim': this.mqttData.rightAspirator.value ? 'rotate360 linear 2s infinite' : 'none',
        '--right-filter-aspirator-color': this.mqttData.rightAspirator.value ? 'lightgreen' : 'lightgrey',
        
        '--rot-filter-bl-anim': this.mqttData.rotativeFilterBL.value ? 'rotate360 linear 5s infinite' : 'none',
        '--rot-filter-br-anim': this.mqttData.rotativeFilterBR.value ? 'rotate360 linear 5s infinite' : 'none',
        '--rot-filter-tl-anim': this.mqttData.rotativeFilterTL.value ? 'rotate360 linear 5s infinite' : 'none',
        '--rot-filter-tr-anim': this.mqttData.rotativeFilterTR.value ? 'rotate360 linear 5s infinite' : 'none',
        '--rot-filter-bl-color': this.mqttData.rotativeFilterBL.value ? 'lightgreen' : 'lightgrey',
        '--rot-filter-br-color': this.mqttData.rotativeFilterBR.value ? 'lightgreen' : 'lightgrey',
        '--rot-filter-tl-color': this.mqttData.rotativeFilterTL.value ? 'lightgreen' : 'lightgrey',
        '--rot-filter-tr-color': this.mqttData.rotativeFilterTR.value ? 'lightgreen' : 'lightgrey',

        '--rot-filter-br-s-anim': this.mqttData.rotativeFilterBR.value ? 'minimizeScale 5s cubic-bezier(0, 0.4, 1, 0.6) infinite' : 'none',
        '--rot-filter-tr-s-anim': this.mqttData.rotativeFilterTR.value ? 'minimizeScale 5s cubic-bezier(0, 0.4, 1, 0.6) infinite' : 'none',

        '--screw-aspirator-anim': this.mqttData.screwAspirator.value ? 'rotate360 linear 2s infinite' : 'none',
        '--screw-aspirator-color': this.mqttData.screwAspirator.value ? 'lightgreen' : 'lightgrey',
        '--screw-conveyor-anim': this.mqttData.screwConveyor.value ? 'rotate360 linear 2s infinite' : 'none',
        '--screw-conveyor-color': this.mqttData.screwConveyor.value ? 'lightgreen' : 'lightgrey',

        '--vt1-anim': this.mqttData.warmAirFan.value ? 'rotate360 linear 2s infinite' : 'none',
        '--vt1-color': this.mqttData.warmAirFan.value ? 'lightgreen' : 'lightgrey',
        
        '--door1-color': this.mqttData.door1.value ? 'lightgreen' : 'red',
        '--door2-color': this.mqttData.door2.value ? 'lightgreen' : 'red',
        '--door3-color': this.mqttData.door3.value ? 'lightgreen' : 'red',

        '--oil-side-flap-color': this.mqttData.bigSideFlap.value ? 'lightgreen' : 'lightgreen',
        '--oil-side-flap-anim': this.mqttData.bigSideFlap.value ? 'rotate-90Rev linear 5s' : 'rotate-90 linear 5s',

        '--discharge-anim': this.mqttData.extractFlap.value ? /*en fonctionnement*/'none' : /*arrêt*/'rotate90 linear 3s',
        '--discharge-color': this.mqttData.extractFlap.value ? /*en fonctionnement*/'#818181' : /*arrêt*/'lightgreen',

        '--cooling-state-color': this.mqttData.coolingState.value ? 'lightblue' : '#ffea00',
        '--running-anim-state': this.mqttData.running.value ? 'running' : 'paused',

        '--bBurnerRunning': this.mqttData.bBurnerRunning.value ? /*en fonctionnement*/'inline' : /*arrêt*/'none',
        '--tBurnerRunning': this.mqttData.tBurnerRunning.value ? /*en fonctionnement*/'inline' : /*arrêt*/'none',
        '--extractFlap': this.mqttData.extractFlap.value ? /*en fonctionnement*/'#00d400' : /*arrêt*/'#818181',
        '--extractAll': this.mqttData.extractAll.value ? /*en fonctionnement*/'#00d400' : /*arrêt*/'#818181',
        '--extractLeft': this.mqttData.extractLeft.value ? /*en fonctionnement*/'#00d400' : /*arrêt*/'#818181',

        //'--cooling-state-color': this.mqttData.coolingState.value ? /*en fonctionnement*/'#8ad4ff' : /*arrêt*/'url(#pattern56949)',
        '--grainLowLevel': this.mqttData.lowLevel.value && !this.mqttData.highLevel.value ? /*plein*/'inline' : /*pas de grain*/'none',
        /* @todo */
        '--grainMiddleLevel': this.mqttData.lowLevel.value && !this.mqttData.highLevel.value ? /*plein*/'none' : /*pas de grain*/'none',
        '--grainHighLevel': !this.mqttData.lowLevel.value && this.mqttData.highLevel.value ? /*plein*/'inline' : /*pas de grain*/'none',
      };
    }
  },

  watch: {
    mqttData: function(newVal) {
      // update top burner temperature label
      if (typeof newVal['40301'].value !== 'undefined') {
        let el = document.querySelector('#dlr-top-burner-temp text tspan');
        if (el) el.textContent = newVal['40301'].value;
      }
      // update bottom burner temperature label
      if (typeof newVal['40300'].value !== 'undefined') {
        let el = document.querySelector('#dlr-bottom-burner-temp text tspan');
        if (el) el.textContent = newVal['40300'].value;
      }

      // update extractions timer label
      if (typeof newVal['40307'].value !== 'undefined') {
        let el = document.querySelector('#dlrb-extractions-timer text tspan');
        if (el) el.textContent = newVal['40307'].value;
      }

      // update sensors temperature label
      let j = '';
      for (let i = 1; i <= 16; i++) {
        j = '405' + i;
        if (i < 10) j = '4050' + i;

        if (typeof newVal[j].value !== 'undefined') {
          let el = document.querySelector('#dlrb-sensor' + i + ' text tspan');
          if (el) el.textContent = newVal[j].value;
        }
      }
    }
  },

  mounted: function() {
    /*console.log(this.$el);*/
  },

  methods: {
    onClick: function(event) {
      const target = event.target || event.srcElement;
      if (event.target.matches('.clickable')) console.log("object id: " + target.id);
      //console.log(event);
      //console.log("not object id: " + target.id);
    },
    toggleLayer: function(state, layer) {
      let display = 'none';
      if (state) {
        display = 'inline';
      }

      document.querySelector(layer).style.display = display;
    }
  }
}
</script>

<style>
/* animation keyframes */
@keyframes blinkingRed {
  0%   { fill: #ff0000; }
  50%  { fill: #ffffff; }
  100% { fill: #ff0000; }
}

@keyframes workingBurner {
  0%   { fill: #ffdc64; }
  50%  { fill: #fc5c00; }
  100% { fill: #ffdc64; }
}
@keyframes workingBurnerBis {
  0%   { fill: #ff0; }
  50%  { fill: #fff; }
  100% { fill: #ff0; }
}
@keyframes workingBurnerScale {
  /*0%   { transform: scale(1, 1); transform-origin: 50% 50%; }
  100% { transform: scale(0.9, 0.9); transform-origin: 50% 50%; }*/
}

@keyframes vaporCloud1 {
  0% {
    opacity: 0;
    transform: translate(none);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: translate(20px, -20px);
  }
}
@keyframes vaporCloud2 {
  0% {
    opacity: 0;
    transform: translate(none);
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
    transform: translate(15px, -25px);
  }
}

@keyframes rotate-90 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-90deg);
  }
}
@keyframes rotate-90Rev {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes rotate90 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes minimizeScale {
  0% {
    transform: scale(1, 0);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 0);
  }
}

@keyframes doorOpen {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(7, 1);
  }
}

/*
add in svg element:
preserveAspectRatio="xMinYMin meet"
*/

#dryer-main {
  pointer-events: none;
}
.clickable {
  pointer-events: auto;
}


/* airflow */
#dlr-airflow {
  display: none;
}

/* vapor clouds */
#dlr-cloud1 {
  animation: vaporCloud1;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 0;
  animation-play-state: var(--running-anim-state);
}
#dlr-cloud2 {
  animation: vaporCloud2;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  animation-play-state: var(--running-anim-state);
}
#dlr-cloud3 {
  animation: vaporCloud1;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 3s;
  animation-play-state: var(--running-anim-state);
}


/* status */
#dlr-b-drying-section, #dlr-t-drying-section, #dlr-cool-section,
#dlrb-b-drying-section, #dlrb-t-drying-section, #dlrb-cool-section {
  /*fill: var(--cooling-state-color);*/
}
/* @todo PATTERN #pattern56949 */
#rect56837-4 {
  fill: var(--cooling-state-color);
}


/* burners */
#dlr-t-burner-active {
  /*fill: none;
  stroke: none;*/
  display: var(--tBurnerRunning);
  /*display: inline;
  animation: workingBurnerScale linear 2s infinite;*/
}
#dlr-t-burner-active #path1501-3-2-4 {
  animation: workingBurner linear 2s infinite;
}
#dlr-t-burner-active #path1503-9-9-7 {
  animation: workingBurnerBis linear 3s infinite;
}
#dlr-b-burner-active {
  /*fill: none;
  stroke: none;*/
  display: var(--bBurnerRunning);
}
#dlr-b-burner-active #path1501-3-2-4-0 {
  animation: workingBurner linear 2s infinite;
}
#dlr-b-burner-active #path1503-9-9-7-3 {
  animation: workingBurnerBis linear 3s infinite;
}


/* airflow sensor */
#dlr-airflow-sensor {
  fill: var(--airflow-sensor-color);
}


/* VT1 flap */
#dlr-vt1-flap {
  fill: var(--extractFlap);
}


/* buffer bin level */
#highLevel {
  display: var(--highLevel);
}
#lowLevel {
  display: var(--lowLevel);
}

#dlr-low-level, #dlrb-low-level {
  display: var(--grainLowLevel);
}
#dlr-mid-level, #dlrb-mid-level {
  display: var(--grainMiddleLevel);
}
#dlr-high-level, #dlrb-high-level {
  display: var(--grainHighLevel);
}


/* general */
#dlr-elect-cab {
  fill: var(--general-color);
}
#dlr-air-comp {
  fill: var(--compressor-color);
}


/* redler */
#dlr-b-redler, #dlrb-b-redler {
  display: inline-block;
  animation: var(--redler);
}


/* doors */
#dlrb-buff-door {
  fill: var(--door1-color); /* @todo correct? */
}
#dlrb-main-fan-door {
  fill: var(--door2-color); /* @todo correct? */
}
#dlrb-filters-door {
  fill: var(--door3-color); /* @todo correct? */
}
/*#dlrb-buff-door,
#dlrb-filters-door,
#dlrb-main-fan-door {
  animation: doorOpen;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: top right;
  transform-box: fill-box;
  vector-effect: non-scaling-stroke;
}*/

#dlr-oil-side-flap {
  fill: var(--oil-side-flap-color);
}
#dlr-oil-side-flap {
/* default:
animation-delay: 0;
animation-direction: normal;
animation-iteration-count: 1;
*/
  animation: var(--oil-side-flap-anim);
  transform-origin: top left;
  transform-box: fill-box;
}


/* discharger */
#dlr-discharger-body,
#dlrb-discharger-body {
  fill: var(--discharge-color);
}

#dlr-discharger-els > *,
#dlrb-discharger-els > * {
  animation: var(--discharge-anim);
  transform-box: fill-box;
  transform-origin: 50% 50%;
}


/* vt1 */
#dlr-main-fan > path {
  fill: var(--vt1-color);
}
#dlr-main-fan-blades {
  animation: var(--vt1-anim);
  transform-box: fill-box;
  transform-origin: 50% 50%;
}


/* screw conveyor */
#dlr-screw-asp > #path89454 { /* @todo change identifier to dlr-screw-asp-body */
  fill: var(--screw-aspirator-color);
}
#dlr-screw-asp-fan {
  animation: var(--screw-aspirator-anim);
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
#dlr-screw-convey > circle {
  fill: var(--screw-conveyor-color);
}
#dlr-screw-convey > path {
  animation: var(--screw-conveyor-anim);
  transform-box: fill-box;
  transform-origin: 50% 50%;
}


/* filter aspirators */
#dlr-r-asp > path {
  fill: var(--right-filter-aspirator-color);
}
#dlr-right-asp-fan {
  animation: var(--right-filter-aspirator-anim);
  transform-box: fill-box;
  transform-origin: 50% 50%;
}


/* rotating filters */
#dlrb-scraper-bl,
#dlrb-scraper-br,
#dlrb-scraper-tl,
#dlrb-scraper-tr {
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

#dlr-rot-filter-bl > *, #dlrb-rot-filter-bl ellipse {
  fill: var(--rot-filter-bl-color);
}
#dlrb-scraper-bl {
  animation: var(--rot-filter-bl-anim);
}
#dlr-rot-filter-br > *, #dlrb-rot-filter-br ellipse {
  fill: var(--rot-filter-br-color);
}
#dlrb-scraper-br {
  animation: var(--rot-filter-br-anim);
}
#dlr-rot-filter-tl > *, #dlrb-rot-filter-tl ellipse {
  fill: var(--rot-filter-tl-color);
}
#dlrb-scraper-tl {
  animation: var(--rot-filter-tl-anim);
}
#dlr-rot-filter-tr > *, #dlrb-rot-filter-tr ellipse {
  fill: var(--rot-filter-tr-color);
}
#dlrb-scraper-tr {
  animation: var(--rot-filter-tr-anim);
}

#dlr-rot-filter-tr-t-scraper,
#dlr-rot-filter-br-t-scraper,
#dlr-rot-filter-tr-b-scraper,
#dlr-rot-filter-br-b-scraper {
  transform-box: fill-box;
  vector-effect: non-scaling-stroke;
}
#dlr-rot-filter-br-b-scraper {
  animation: var(--rot-filter-br-s-anim);
  transform-origin: top left;
}
#dlr-rot-filter-tr-b-scraper {
  animation: var(--rot-filter-tr-s-anim);
  transform-origin: top left;
}
#dlr-rot-filter-br-t-scraper {
  animation: var(--rot-filter-br-s-anim);
  transform-origin: bottom left;
}
#dlr-rot-filter-tr-t-scraper {
  animation: var(--rot-filter-tr-s-anim);
  transform-origin: bottom left;
}
</style>