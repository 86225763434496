<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="95%"
  >
    <!-- activation button -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="blue lighten-1">mdi-finance</v-icon>
      </v-btn>
    </template>

    <!-- dialog content -->
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{sensor && sensor.name}}
      </v-card-title>

      <v-card-text>
        <SensorChart :sensor="sensor" />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          color="primary"
          text
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SensorChart from '@/components/SensorsTable/SensorChart';

export default {
  name: 'sensor-chart-dialog',

  components: {
    SensorChart
  },

  props: [
    'sensor'
  ],

  data: function() {
    return {
      dialog: false
    };
  }
}
</script>