import AuthDataService from '@/services/AuthDataService';

const state = {
  mqtt: JSON.parse(localStorage.getItem('mqtt')) || null, // @todo make an mqtt uniq token
  status: '',
  token: localStorage.getItem('user-token') || ''
  //hasLoadedOnce: false
};

const getters = {
  getAuthStatus: state => state.status,
  isAuthenticated: state => !!state.token
};

const actions = {
  autoLogin: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('SET_AUTH_STATUS', 'loading');
      dispatch('userRequest')
        .then(function(userData) {
          commit('SET_AUTH_MQTT', userData.mqtt);
          commit('SET_AUTH_TOKEN', userData.token);
          commit('SET_AUTH_STATUS', 'success');
          resolve(userData);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  loginRequest: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit('SET_AUTH_STATUS', 'loading');

      AuthDataService.login(user)
        .then((response) => {
          let userData = response.data.user;
          console.log(response.data);
          localStorage.setItem('user-token', userData.token);
          localStorage.setItem('mqtt', JSON.stringify(userData.mqtt));

          // Here set the header of your ajax library to the token value.
          // example with axios
          // axios.defaults.headers.common['Authorization'] = data.token
          AuthDataService.setToken(userData.token); // @todo
          commit('SET_AUTH_MQTT', userData.mqtt);
          commit('SET_AUTH_TOKEN', userData.token);
          commit('SET_AUTH_STATUS', 'success');
          dispatch('userRequest');
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          commit('SET_AUTH_STATUS', 'error');
          localStorage.removeItem('user-token');
          localStorage.removeItem('mqtt');
          reject(err);
        });
    });
  },

  registerRequest: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit('SET_AUTH_STATUS', 'loading');

      AuthDataService.register(user)
        .then(function(response) {
          console.log(response.data);
          commit('SET_AUTH_STATUS', 'success');
          dispatch('userRequest');
          resolve(response);
        })
        .catch(function(err) {
          console.log(err.response.data.message);
          commit('SET_AUTH_STATUS', 'error');
          reject(err);
        });
    });
  },

  confirmEmailRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit('SET_AUTH_STATUS', 'loading');

      AuthDataService.confirmEmail(data)
        .then(function(response) {
          console.log(response.data);
          commit('SET_AUTH_STATUS', 'success');
          dispatch('userRequest');
          resolve(response);
        })
        .catch(function(err) {
          console.log(err.response.data.message);
          commit('SET_AUTH_STATUS', 'error');
          reject(err);
        });
    });
  },

  sendResetPasswordLinkRequest: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit('SET_AUTH_STATUS', 'loading');

      AuthDataService.sendResetPasswordLink(data)
        .then(function(response) {
          console.log(response.data);
          commit('SET_AUTH_STATUS', 'success');
//          dispatch('userRequest');
          resolve(response);
        })
        .catch(function(err) {
          console.log(err.response.data.message);
          commit('SET_AUTH_STATUS', 'error');
          reject(err);
        });
    });
  },

  resetLostPasswordRequest: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit('SET_AUTH_STATUS', 'loading');

      AuthDataService.resetLostPassword(data)
        .then(function(response) {
          console.log(response.data);
          commit('SET_AUTH_STATUS', 'success');
//          dispatch('userRequest');
          resolve(response);
        })
        .catch(function(err) {
          console.log(err.response.data.message);
          commit('SET_AUTH_STATUS', 'error');
          reject(err);
        });
    });
  },

  logoutRequest: ({ commit }) => {
    return new Promise(resolve => {
      localStorage.removeItem('user-token');
      localStorage.removeItem('mqtt'); // @todo disconnect mqtt
      commit('SET_USER_PROFILE', {});
      commit('SET_AUTH_MQTT', null);
      commit('SET_AUTH_TOKEN', '');
      commit('SET_AUTH_STATUS', 'success');
      resolve();
    });
  }
};

const mutations = {
  SET_AUTH_MQTT: (state, data) => {
    state.mqtt = data;
  },
  SET_AUTH_STATUS: (state, status) => {
    state.status = status;
  },
  SET_AUTH_TOKEN: (state, token) => {
    state.token = token;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};