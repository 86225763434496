<template>
  <v-navigation-drawer v-model="drawerState" app temporary>
    <v-list nav dense>
      <v-list-item-group
        active-class="light-blue--text text--accent-4"
      >
        <v-list-item to="/" exact>
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Accueil</v-list-item-title>
        </v-list-item>

        <v-list-item to="/sensors">
          <v-list-item-icon>
            <v-icon>mdi-thermometer</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sondes</v-list-item-title>
        </v-list-item>

        <v-list-item to="/burners">
          <v-list-item-icon>
            <v-icon>mdi-fire</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Brûleurs</v-list-item-title>
        </v-list-item>

        <v-list-item to="/charts">
          <v-list-item-icon>
            <v-icon>mdi-finance</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Graphes</v-list-item-title>
        </v-list-item>

        <v-list-item to="/tracking">
          <v-list-item-icon>
            <v-icon>mdi-book-open-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Suivi</v-list-item-title>
        </v-list-item>

        <v-list-item to="/notifications">
          <v-list-item-icon>
            <v-icon>mdi-bell-ring-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Notifications</v-list-item-title>
        </v-list-item>

        <v-list-item to="/forcings">
          <v-list-item-icon>
            <v-icon>mdi-gavel</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Forcages</v-list-item-title>
        </v-list-item>

        <hr>

        <v-list-item v-if="isAdmin" to="/playground">
          <v-list-item-icon>
            <v-icon>mdi-test-tube</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tests</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isAdmin" to="/debug">
          <v-list-item-icon>
            <v-icon>mdi-table-eye</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Debug</v-list-item-title>
        </v-list-item>

        <v-list-item to="/status">
          <v-list-item-icon>
            <v-icon>mdi-server-network</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Etat du système</v-list-item-title>
        </v-list-item>

        <v-list-item to="/about">
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-title>A propos</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  computed: {
    drawerState: {
      get: function() {
        return this.$store.getters.drawerState;
      },
      set: function(v) {
        return this.$store.commit('toggleDrawerState', v);
      }
    },
    isAdmin: function() {
      // check current user identifier
      // @todo rbac permissions
      return this.$store.getters.getUserProfile.id === 1;
    }
  }
}
</script>
