import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Burners from '@/views/Burners.vue';
import Charts from '@/views/Charts.vue';
import ConfirmEmail from '@/views/auth/ConfirmEmail.vue';
import Diagram from '@/views/Diagram.vue';
import Debug from '@/views/Debug.vue';
import Forcings from '@/views/Forcings.vue';
import Login from '@/views/auth/Login.vue';
import LostPassword from '@/views/auth/LostPassword.vue';
import Notifications from '@/views/Notifications.vue';
import Playground from '@/views/Playground.vue';
import Profile from '@/views/Profile.vue';
import Register from '@/views/auth/Register.vue';
import ResetLostPassword from '@/views/auth/ResetLostPassword.vue';
import Sensors from '@/views/Sensors.vue';
import Status from '@/views/Status.vue';
import Tracking from '@/views/Tracking.vue';

Vue.use(VueRouter);

// navigation guards
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
  } else {
    next({
      path: '/login',
      query: { // passing requested page path to redirect after login
        redir: to.fullPath
      }
    });

    // close drawer properly if login page is already opened
    store.commit('toggleDrawerState', false);
  }
};

const router = new VueRouter({
  // @todo do server configuration before using history mode
  // @see https://stackoverflow.com/a/51340156
  //mode: 'history',

  routes: [
    {
      path: '/',
      name: 'home',
      components: {
        default: Diagram
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/sensors',
      name: 'sensors',
      components: {
        default: Sensors
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/burners',
      name: 'burners',
      components: {
        default: Burners
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/charts',
      name: 'charts',
      components: {
        default: Charts
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/tracking',
      name: 'tracking',
      components: {
        default: Tracking
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/notifications',
      name: 'notifications',
      components: {
        default: Notifications
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/forcings',
      name: 'forcings',
      components: {
        default: Forcings
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/playground',
      name: 'playground',
      components: {
        default: Playground
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/debug',
      name: 'debug',
      components: {
        default: Debug
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/status',
      name: 'status',
      component: Status,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/login',
      name: 'login',
      components: {
        default: Login
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/lost-password',
      name: 'lost-password',
      components: {
        default: LostPassword
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      components: {
        default: ResetLostPassword
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/register',
      name: 'register',
      components: {
        default: Register
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/confirm-email',
      name: 'confirm-email',
      components: {
        default: ConfirmEmail
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/profile',
      name: 'profile',
      components: {
        default: Profile
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  ]
});

router.afterEach((/*to, from*/) => {
  console.log('after routing');

  // reset fab context
  store.dispatch('fab/set', {
    action: null,
    color: 'primary',
    icon: 'mdi-plus'
  });
});

export default router;
