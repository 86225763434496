const modbusMap = {
    40130: { name: "Défauts séchoir", desc: "", divider: 1, signed: false },
    40131: { name: "Défauts séchoir", desc: "", divider: 1, signed: false },
    40132: { name: "Défauts séchoir", desc: "", divider: 1, signed: false },
    40133: { name: "Défauts séchoir", desc: "", divider: 1, signed: false },
    40134: { name: "Défauts séchoir", desc: "", divider: 1, signed: false },
    40135: { name: "Défauts séchoir", desc: "", divider: 1, signed: false },
    40136: { name: "Défauts séchoir", desc: "", divider: 1, signed: false },
    40137: { name: "Défauts séchoir", desc: "", divider: 1, signed: false },

    40150: { name: "Défauts sondes 1er seuil", desc: "", divider: 1, signed: false },
    40151: { name: "Défauts sondes 1er seuil", desc: "", divider: 1, signed: false },

    40154: { name: "Défauts sondes 2éme seuil", desc: "", divider: 1, signed: false },
    40155: { name: "Défauts sondes 2éme seuil", desc: "", divider: 1, signed: false },

    40162: { name: "Inhibition sonde inférieure", desc: "", divider: 1, signed: false },
    40163: { name: "Inhibition sonde supérieure", desc: "", divider: 1, signed: false },

    40300: { name: "Température brûleur inf.", desc: "", divider: 10, signed: true },
    40301: { name: "Température brûleur sup.", desc: "", divider: 10, signed: true },
    40302: { name: "Température grain", desc: "", divider: 10, signed: true },
    40303: { name: "Consigne grille extraction", desc: "", divider: 100, signed: false, min: 0.6, max: 3 },
    40304: { name: "Consigne grille n°2", desc: "", divider: 100, signed: false, min: 0.6, max: 3 }, // ?
    40305: { name: "Consigne minuterie", desc: "", divider: 1, signed: false, min: 30, max: 600 },
    40306: { name: "Consigne minuterie calculée", desc: "", divider: 1, signed: false },
    40307: { name: "Décompte intervalle extraction", desc: "", divider: 1, signed: false },
    40308: { name: "Consigne ventilation retardée", desc: "", divider: 1, signed: false, min: 10, max: 600 },
    40309: { name: "Décompte ventilation retardée", desc: "", divider: 1, signed: false },
    40310: { name: "Consigne brûleur inférieur", desc: "", divider: 1, signed: false, min: 30, max: 125 },
    40311: { name: "Consigne brûleur supérieur", desc: "", divider: 1, signed: false, min: 30, max: 140 },
    40312: { name: "Consigne température grain", desc: "", divider: 10, signed: false, min: 30, max: 80 },
    40313: { name: "Coefficient de régulation", desc: "", divider: 10, signed: false, min: 0.5, max: 8 },
    40314: { name: "Temps fermeture volet après extraction", desc: "", divider: 10, signed: false, min: 5, max: 15 },
    40315: { name: "Compteur extractions journalier", desc: "", divider: 1, signed: false },
    40316: { name: "Durée fonctionnement brûleur inférieur", desc: "", divider: 1, signed: false },
    40317: { name: "Durée fonctionnement brûleur supérieur", desc: "", divider: 1, signed: false },
    40318: { name: "Seuil défaut brûleur inférieur", desc: "", divider: 1, signed: false },
    40319: { name: "Seuil défaut brûleur supérieur", desc: "", divider: 1, signed: false },
    40320: { name: "Cellule de destination", desc: "", divider: 1, signed: false, min: 0, max: 10000 },
    40321: { name: "Humidité de sortie", desc: "", divider: 10, signed: false, min: 6, max: 20 },
    40322: { name: "Humidité d'entrée", desc: "", divider: 10, signed: false, min: 9, max: 50 },
    40323: { name: "", desc: "", divider: 1, signed: false },
    40324: { name: "", desc: "", divider: 1, signed: false },
    40325: { name: "", desc: "", divider: 1, signed: false },
    40326: { name: "", desc: "", divider: 1, signed: false },
    40327: { name: "", desc: "", divider: 1, signed: false },
    40328: { name: "Compteur extractions total", desc: "", divider: 1, signed: false },
    40329: { name: "", desc: "", divider: 1, signed: false },
    40330: { name: "", desc: "", divider: 10, signed: true, min: 0, max: 100 },
    40331: { name: "", desc: "", divider: 10, signed: true, min: 0, max: 100 },

    40501: { name: "Sonde n°1", desc: "Valeur sonde air usé n°1", divider: 10, signed: true },
    40502: { name: "Sonde n°2", desc: "Valeur sonde air usé n°2", divider: 10, signed: true },
    40503: { name: "Sonde n°3", desc: "Valeur sonde air usé n°3", divider: 10, signed: true },
    40504: { name: "Sonde n°4", desc: "Valeur sonde air usé n°4", divider: 10, signed: true },
    40505: { name: "Sonde n°5", desc: "Valeur sonde air usé n°5", divider: 10, signed: true },
    40506: { name: "Sonde n°6", desc: "Valeur sonde air usé n°6", divider: 10, signed: true },
    40507: { name: "Sonde n°7", desc: "Valeur sonde air usé n°7", divider: 10, signed: true },
    40508: { name: "Sonde n°8", desc: "Valeur sonde air usé n°8", divider: 10, signed: true },
    40509: { name: "Sonde n°9", desc: "Valeur sonde air usé n°9", divider: 10, signed: true },
    40510: { name: "Sonde n°10", desc: "Valeur sonde air usé n°10", divider: 10, signed: true },
    40511: { name: "Sonde n°11", desc: "Valeur sonde air usé n°11", divider: 10, signed: true },
    40512: { name: "Sonde n°12", desc: "Valeur sonde air usé n°12", divider: 10, signed: true },
    40513: { name: "Sonde n°13", desc: "Valeur sonde air usé n°13", divider: 10, signed: true },
    40514: { name: "Sonde n°14", desc: "Valeur sonde air usé n°14", divider: 10, signed: true },
    40515: { name: "Sonde n°15", desc: "Valeur sonde air usé n°15", divider: 10, signed: true },
    40516: { name: "Sonde n°16", desc: "Valeur sonde air usé n°16", divider: 10, signed: true },

    40601: { name: "Consigne sonde air usé n°1", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40602: { name: "Consigne sonde air usé n°2", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40603: { name: "Consigne sonde air usé n°3", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40604: { name: "Consigne sonde air usé n°4", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40605: { name: "Consigne sonde air usé n°5", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40606: { name: "Consigne sonde air usé n°6", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40607: { name: "Consigne sonde air usé n°7", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40608: { name: "Consigne sonde air usé n°8", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40609: { name: "Consigne sonde air usé n°9", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40610: { name: "Consigne sonde air usé n°10", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40611: { name: "Consigne sonde air usé n°11", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40612: { name: "Consigne sonde air usé n°12", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40613: { name: "Consigne sonde air usé n°13", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40614: { name: "Consigne sonde air usé n°14", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40615: { name: "Consigne sonde air usé n°15", desc: "", divider: 10, signed: true, min: 20, max: 90 },
    40616: { name: "Consigne sonde air usé n°16", desc: "", divider: 10, signed: true, min: 20, max: 90 },
};

export default modbusMap;