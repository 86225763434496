<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th colspan="2" class="text-left">
              Système
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, key) in emitter.sys"
            :key="key"
          >
            <td>{{ key }}</td>
            <td v-if="key === 'uptime'">{{ (value / 1000 / 3600).toFixed(2) }}h</td>
            <td v-else>{{ value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th colspan="2" class="text-left">
              Réseau
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, key) in emitter.net"
            :key="key"
          >
            <td>{{ key }}</td>
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th colspan="2" class="text-left">
              Mémoire
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, key) in emitter.flash"
            :key="key"
          >
            <td>{{ key }}</td>
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      emitter: {} // net, sys, flash
    }
  },

  created: async function() {
    this.$store.dispatch('mqttOnMessage', this.onMqttMessage);

    await this.$store.dispatch('mqttSubscribe', 'coopta/dryer/devices/#');

    this.$store.dispatch('mqttPublish', {
      topic: 'coopta/dryer/commands/sysinfo',
      msg: 'get',
      cb: function(error) { console.log(error); }
    });
  },

  beforeDestroy: function() {
    let promise = this.$store.dispatch('mqttUnsubscribe', 'coopta/dryer/devices/#');
    promise.catch(function(error) {
      console.log(error);
    });
  },

  methods: {
    onMqttMessage: function(topic, data) {
      if (topic === 'coopta/dryer/devices/emitter/status') {
        const status = { status: data.toString() };
        this.emitter = Object.assign({}, this.emitter, status);
      }
      if (topic === 'coopta/dryer/devices/emitter/sysinfo') {
        let json = null;
        try {
          json = JSON.parse(data.toString());
        } catch(e) {
          console.error('Caught a malformed JSON from MQTT ("src/components/ESP8266/Status.vue" l.107)');
          return;
        }

        this.emitter = Object.assign({}, this.emitter, json);
      }
      if (topic === 'coopta/dryer/devices/emitter/version') {
        const version = { version: data.toString() };
        this.emitter = Object.assign({}, this.emitter, version);
      }
    },

    getSysinfo: function() {
      // @todo handle error
      this.$store.getters.getMqttClient.publish('coopta/dryer/commands/sysinfo', 'get', (err) => {
        err;
        console.log('Getting esp8266 sysinfo...');
      });
    }
  }
};
</script>