<template>
  <v-app>
    <!-- navigation drawer -->
    <Navigation/>

    <!-- main app bar -->
    <v-app-bar
      app
      color="green"
      dark
      extension-height="0"
      hide-on-scroll
    >
      <v-app-bar-nav-icon @click="drawerState = !drawerState"></v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <v-img
          alt="Logo Terre Atlantique"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo.svg')"
          transition="scale-transition"
          width="110"
        />

        <v-toolbar-title class="d-none d-sm-flex">Séchoir</v-toolbar-title>
      </div>
      
      <v-spacer></v-spacer>

      <!-- connection status button -->
      <ConnectionStatus :mqttData="mqttData" />

      <!-- night mode button -->
      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-{{buttonText}}</v-icon>
      </v-btn>

      <!-- notification list button -->
      <v-badge
        color="error"
        offset-x="21px"
        offset-y="21px"
        overlap
        :content="notificationCount"
        :value="notificationCount"
      >
        <v-btn icon @click="toggleNotifications">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>

      <!-- user menu button -->
      <UserMenu />

      <!-- slot:extension -->
      <template v-slot:extension>
        <!-- alarm banner -->
        <AlarmsBanner :mqttData="mqttData" />

        <!-- loader -->
        <AppProgressBar/>
      </template>
    </v-app-bar>

    <!-- footer navbar -->
    <AppFooterNavBar :mqttData="mqttData" />

    <!-- snackbar -->
    <AppSnackbar/>

    <!-- content -->
    <Main :mqttData="mqttData" />

  </v-app>
</template>

<script>
import AlarmsBanner from '@/components/AlarmsBanner';
import AppFooterNavBar from '@/components/AppFooterNavBar';
import AppProgressBar from '@/components/AppProgressBar';
import AppSnackbar from '@/components/AppSnackbar';
import ConnectionStatus from '@/components/ConnectionStatus';
import Main from '@/components/Main';
import Navigation from '@/components/Navigation';
import UserMenu from '@/components/UserMenu';
import MASK from '@/data/binaryMaskMap.js';
import modbusMap from '@/data/modbusMap.js';

export default {
  name: 'App',

  components: {
    AlarmsBanner,
    AppFooterNavBar,
    AppProgressBar,
    AppSnackbar,
    ConnectionStatus,
    Main,
    Navigation,
    UserMenu
  },

  data: function() {
    return {
      mqttData: [],
      loading: false,
      loadingSnackbar: false,
      snackbarText: 'Chargement...'
    };
  },

  computed: {
    drawerState: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        return this.$store.commit('toggleDrawerState', v);
      }
    },
    buttonText: function() {
      return !this.$vuetify.theme.dark ? 'weather-night' : 'weather-sunny';
    },
    notificationCount: function() {
      return this.$store.getters['notifications/getCount'];
    }
  },

  created: function() {
    // autologin on load
    this.$store.dispatch('autoLogin');

    var self = this;

    // try to connect to MQTT broker
    this.$store.dispatch('connectMqtt')
      .then(function(client) {
        client.subscribe('coopta/dryer/#');
        client.on('message', function(topic, message) {
          self.onMqttMessage(message, topic);
        });
      }).catch(function() {
        console.log('mqtt hard fail!');
      });
  },

  methods: {
    toggleTheme: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },

    toggleNotifications: function() {
      // @todo
      console.log('show notifications');
    },

    onMqttMessage: function(data, topic) {
      // discard all other messages
      if (topic !== 'coopta/dryer/raw') {
        return;
      }

      // parse json
      let json = null;

      try {
        json = JSON.parse(data.toString());
      } catch(e) {
        console.error('Caught a malformed JSON from MQTT ("src/App.vue" l.97)');
        return; // discard, we'll try the next message...
      }

/*
// @todo DEBUG LATENCY
const t = new Date();
let v = json['time'].replace(/000$/g, "Z");
v = v.replace(/(:[0-9]{2})$/g, "$1.000Z");
const u = new Date(Date.parse(v));
//console.info('Delta:', Math.floor((u.getTime() - t.getTime()) / 1000), 'seconds');
console.info('Delta:', (u.getTime() - t.getTime()) / 1000, 'seconds');
*/

      var obj = {};
      obj['alarms'] = [];
      var name = '';
      var divider = 1;
      var signed = false;
      var value = 0;

      // for each key in json
      for (const key in json) {
        name = 'no name';
        divider = 1;
        signed = false;

        if (typeof modbusMap[key] !== 'undefined') {
          name = modbusMap[key].name;
          divider = modbusMap[key].divider;
          signed = modbusMap[key].signed;
        }

        // regular value
        if (signed) {
          // convert to signed binary
          let binStr = (json[key] >>> 0).toString(2);
          binStr = binStr.length >= 16 && binStr[0] === "1" ? binStr.padStart(32, "1"): binStr.padStart(32, "0");
          value = parseInt(binStr, 2) >> 0; // convert to signed decimal
        } else {
          value = json[key];
        }

        obj[key] = {
          value: value / divider,
          name: name
        };

        // decode bits for values with merged states
        if (typeof MASK[key] !== 'undefined') {
          //for (let el in MASK[key]) {
          for (let i = 0, len = MASK[key].length; i < len; i++) {
            let el = MASK[key][i];
            //obj[401101/*base_key -concat- bits*/] = {
            obj[el.key] = {
              value: (json[key] & el.mask) !== 0,
              name: el.name
            };

            // add alarms if exists
            let alarmKeys = {
              40130: null,
              40131: null,
              40132: null,
              40133: null,
              40134: null,
              40135: null,
              40136: null,
              40137: null
            };

            if (typeof alarmKeys[key] !== 'undefined' && (json[key] & el.mask) !== 0) {
              obj['alarms'].push({
                name: el.name,
                register: key,
                registerValue: json[key]
              });
            }
          }
        }
      }

      this.mqttData = obj;
    }
  }
};
</script>

<style>
/* fix progress bar @see https://medium.com/js-dojo/how-to-visualize-application-loading-state-in-vuetify-44f0f0242094 */
.v-toolbar__extension {
  padding: 2px 0 0 0 !important;
}
</style>