const MASK = {
  40100: [
    {
      key: 'general',
      name: 'Général',
      mask: 1//0b00000001
    },
    {
      key: 'inter',
      name: 'Inter',
      mask: 2//0b00000010
    },
    {
      key: 'air',
      name: 'Présence air comprimé',
      mask: 4//0b00000100
    },
    {
      key: 'unused1',
      name: 'not used',
      mask: 8//0b00001000
    },
    {
      key: 'door1',
      name: 'Porte vis/ventilateur',
      mask: 16//0b00010000
    },
    {
      key: 'door2',
      name: 'Porte aspirateur',
      mask: 32//0b00100000
    },
    {
      key: 'unused2',
      name: 'not used',
      mask: 64//0b01000000
    },
    {
      key: 'bigSideFlap',
      name: 'Volets tournesol',
      mask: 128//0b10000000
    },
    {
      key: 'airFlowCheck',
      name: 'Contrôle débit air brûleur inférieur',
      mask: 256//0b000100000000
    },
    {
      key: 'door3',
      name: 'Porte filtre supérieur/porte dépoussièrage',
      mask: 512//0b001000000000
    },
    {
      key: 'coolingState',
      name: 'Cycle refroidissement',
      mask: 8192//0b0001000000000000
    },
    {
      key: 'running',
      name: 'Cycle (séchage)',
      mask: 16384//0b0010000000000000
    },
    {
      key: 'defaults',
      name: 'En défaut',
      mask: 32768//0b0100000000000000
    }
  ],
  40101: [
    {
      key: 'screwAspirator',
      name: 'Vis à poussière (aspirateur)',
      mask: 8//0b00001000
    },
    {
      key: 'screwConveyor',
      name: 'Vis à poussière',
      mask: 32//0b00100000
    }
  ],
  40102: [
    {
      key: 'disj',
      name: 'Disj',
      mask: 1//0b00000001
    },
    {
      key: 'ats',
      name: 'ATS + moteur VT1',
      mask: 2//0b00000010
    },
    {
      key: 'warmAirFan',
      name: 'Corps VT1',
      mask: 4//0b00000100
    },
    {
      key: 'unused1',
      name: 'not used',
      mask: 8//0b00001000
    },
    {
      key: 'vt1rect',
      name: 'Rect VT1',
      mask: 16//0b00010000
    },
    {
      key: 'vt1square',
      name: 'Carré VT1',
      mask: 32//0b00100000
    },
    {
      key: 'vt1pulse',
      name: 'Cône VT1',
      mask: 64//0b01000000
    },
    {
      key: 'unused2',
      name: 'not used',
      mask: 128//0b10000000
    },
    {
      key: 'unused3',
      name: 'not used',
      mask: 256//0b000100000000
    },
    {
      key: 'unused4',
      name: 'not used',
      mask: 512//0b001000000000
    },
    {
      key: 'atsMotorDefault',
      name: 'ATS (gris) + moteur VT1 (rouge)',
      mask: 1024//0b010000000000
    },
    {
      key: 'atsDefault',
      name: 'ATS (rouge)',
      mask: 2048//0b100000000000
    },
    {
      key: 'vt1Default',
      name: 'Corps VT1 (rouge)',
      mask: 4096//0b0001000000000000
    },
    {
      key: 'vt1squareDefault',
      name: 'Carré VT1 (rouge)',
      mask: 8192//0b0010000000000000
    }
  ],
  40110: [
    {
      key: 'extractFlap',
      name: 'Volet extraction',
      mask: 1//0b00000001
    },
    {
      key: 'extractAll',
      name: 'Grille extraction (tous)',
      mask: 2//0b00000010
    },
    {
      key: 'extractLeft',
      name: 'Grille extraction',
      mask: 4//0b00000100
    },
    {
      key: 'lowLevel',
      name: 'Niveau bas réserve',
      mask: 8//0b00001000
    },
    {
      key: 'highLevel',
      name: 'Niveau haut réserve',
      mask: 16//0b00010000
    },
    {
      key: 'reddler',
      name: 'Transporteur vidange',
      mask: 32//0b00100000
    },
    {
      key: 'timer',
      name: 'Type minuterie',
      mask: 64//0b01000000
    },
    {
      key: 'drainAll',
      name: 'Evacuation trémie (tous)',
      mask: 1024//0b010000000000
    },
    {
      key: 'drainLeft',
      name: 'Evacuation trémie',
      mask: 2048//0b100000000000
    },
    {
      key: 'extractFlapRed',
      name: 'Volet extraction (rouge)',
      mask: 4096//0b0001000000000000
    },
    {
      key: 'extractFlapRed2',
      name: 'Volet extraction (rouge bis)',
      mask: 32768//0b0100000000000000
    }
  ],
  40111: [
    {
      key: 'bBurnerRunning',
      name: 'Etat brûleur',
      mask: 1
    },
    {
      key: 'bFilterPressostats',
      name: 'Filtre et pressostats',
      mask: 2
    },
    {
      key: 'bSecurityValve',
      name: 'Vanne de sécurité',
      mask: 4
    },
    {
      key: 'bPilotValve',
      name: 'Vanne pilote',
      mask: 8
    },
    {
      key: 'bBypassValve',
      name: 'Vanne by-pass',
      mask: 16
    },
    {
      key: 'bModulatingValve',
      name: 'Vanne modulante',
      mask: 32
    },
    {
      key: 'bModulatingValve2',
      name: 'Vanne modulante 2',
      mask: 64
    },
    {
      key: 'bModulatingValveHigh',
      name: 'Vanne modulante +',
      mask: 128
    },
    {
      key: 'bModulatingValveLow',
      name: 'Vanne modulante -',
      mask: 256
    },
    {
      key: 'bMini',
      name: 'Mini',
      mask: 512
    },
    {
      key: 'bEmergencyStop',
      name: 'Arrêt d\'urgence (rouge)',
      mask: 1024
    },
    {
      key: 'bBurnerBoxRed',
      name: 'Boîte brûleur (rouge)',
      mask: 2048
    },
    {
      key: 'bCeRed',
      name: 'CE (rouge)',
      mask: 4096
    },
    {
      key: 'bMisc',
      name: 'Divers (rouge)',
      mask: 8192
    },
    {
      key: 'bFilterPressostatsRed',
      name: 'Filtre et pressostats (rouge)',
      mask: 16384
    },
    {
      key: 'bDefaultSynthesis',
      name: 'Synthèse défauts (rouge)',
      mask: 32768
    }
  ],
  40112: [
    {
      key: 'tBurnerRunning',
      name: 'Etat brûleur',
      mask: 1
    },
    {
      key: 'tFilterPressostats',
      name: 'Filtre et pressostats',
      mask: 2
    },
    {
      key: 'tSecurityValve',
      name: 'Vanne de sécurité',
      mask: 4
    },
    {
      key: 'tPilotValve',
      name: 'Vanne pilote',
      mask: 8
    },
    {
      key: 'tBypassValve',
      name: 'Vanne by-pass',
      mask: 16
    },
    {
      key: 'tModulatingValve',
      name: 'Vanne modulante',
      mask: 32
    },
    {
      key: 'tModulatingValve2',
      name: 'Vanne modulante 2',
      mask: 64
    },
    {
      key: 'tModulatingValveHigh',
      name: 'Vanne modulante +',
      mask: 128
    },
    {
      key: 'tModulatingValveLow',
      name: 'Vanne modulante -',
      mask: 256
    },
    {
      key: 'tMini',
      name: 'Mini',
      mask: 512
    },
    {
      key: 'tEmergencyStop',
      name: 'Arrêt d\'urgence (rouge)',
      mask: 1024
    },
    {
      key: 'tBurnerBoxRed',
      name: 'Boîte brûleur (rouge)',
      mask: 2048
    },
    {
      key: 'tCeRed',
      name: 'CE (rouge)',
      mask: 4096
    },
    {
      key: 'tMisc',
      name: 'Divers (rouge)',
      mask: 8192
    },
    {
      key: 'tFilterPressostatsRed',
      name: 'Filtre et pressostats (rouge)',
      mask: 16384
    },
    {
      key: 'tDefaultSynthesis',
      name: 'Synthèse défauts (rouge)',
      mask: 32768
    }
  ],
  40115: [
    {
      key: 'rightAspirator',
      name: 'Aspirateur droit',
      mask: 1
    },
    {
      key: 'rotativeFilterTR',
      name: 'Filtre rotatif haut/droit',
      mask: 2
    },
    {
      key: 'rotativeFilterBR',
      name: 'Filtre rotatif bas/droit',
      mask: 4
    },
    {
      key: 'leftAspirator',
      name: 'Aspirateur gauche',
      mask: 8
    },
    {
      key: 'rotativeFilterTL',
      name: 'Filtre rotatif haut/gauche',
      mask: 16
    },
    {
      key: 'rotativeFilterBL',
      name: 'Filtre rotatif bas/gauche',
      mask: 32
    }
  ],
  40130: [
    {
      key: 'defaultGeneral',
      name: 'Manque Général (réarmer l\'armoire)',
      mask: 1
    },
    {
      key: 'defaultPowerSwitchOpen',
      name: 'Interrupteur de puissance ouvert',
      mask: 2
    },
    {
      key: 'defaultAir',
      name: 'Défaut air comprimé',
      mask: 4
    },
    {
      key: 'defaultWaterPressure',
      name: 'Manque pression eau',
      mask: 8
    },
    {
      key: 'defaultScrewConveyorDoorOpen',
      name: 'Défaut porte vis ouverte',
      mask: 16
    },
    {
      key: 'defaultFanDoorOpen',
      name: 'Défaut porte ventilateur ouverte',
      mask: 32
    },
    {
      key: 'default1',
      name: 'Défaut',
      mask: 64
    },
    {
      key: 'defaultBurnerAirFlow',
      name: 'Défaut débit d\'air brûleur (drapeau)',
      mask: 128
    },
    {
      key: 'default2',
      name: 'Défaut',
      mask: 256
    },
    {
      key: 'defaultFilterDoorOpen',
      name: 'Défaut porte rotatifs ouverte',
      mask: 512
    },
    {
      key: 'default3',
      name: 'Défaut',
      mask: 1024
    },
    {
      key: 'defaultExtraction1',
      name: 'Défaut grille n°1',
      mask: 2048
    },
    {
      key: 'defaultExtraction2',
      name: 'Défaut grille n°2',
      mask: 4096
    },
    {
      key: 'defaultCompressor',
      name: 'Défaut disjonction compresseur',
      mask: 8192
    },
    {
      key: 'defaultAspirator',
      name: 'Défaut disjonction aspirateur inférieur',
      mask: 16384
    },
    {
      key: 'defaultScrewConveyor',
      name: 'Défaut disjonction vis (bourrage)',
      mask: 32768
    }
  ],
  40131: [
    {
      key: 'defaultFan',
      name: 'Défaut disjonction ventilateur VT1',
      mask: 1
    },
    {
      key: 'defaultFanStarter',
      name: 'Défaut démarreur ventilateur VT1',
      mask: 2
    },
    {
      key: 'defaultFanTemperature',
      name: 'Défaut température moteur VT1',
      mask: 4
    },
    {
      key: 'defaultFanStartingTooSlowly',
      name: 'Défaut démarrage trop long VT1',
      mask: 8
    },
    {
      key: 'defaultFanFlap',
      name: 'Défaut volet ventilateur VT1',
      mask: 16
    },
    {
      key: 'defaultFan2',
      name: 'Défaut disjonction ventilateur VT2',
      mask: 32
    },
    {
      key: 'defaultFan2Starter',
      name: 'Défaut démarreur ventilateur VT2',
      mask: 64
    },
    {
      key: 'defaultFan2Temperature',
      name: 'Défaut température moteur VT2',
      mask: 128
    },
    {
      key: 'defaultFan2StartingTooSlowly',
      name: 'Défaut démarrage trop long VT2',
      mask: 256
    },
    {
      key: 'defaultFan2Flap',
      name: 'Défaut volet ventilateur VT2',
      mask: 512
    },
    {
      key: 'defaultFan3',
      name: 'Défaut disjonction ventilateur VT3',
      mask: 1024
    },
    {
      key: 'defaultFan3Starter',
      name: 'Défaut démarreur ventilateur VT3',
      mask: 2048
    },
    {
      key: 'defaultFan3Temperature',
      name: 'Défaut température moteur VT3',
      mask: 4096
    },
    {
      key: 'defaultFan3StartingTooSlowly',
      name: 'Défaut démarrage trop long VT3',
      mask: 8192
    },
    {
      key: 'defaultFan3Flap',
      name: 'Défaut volet ventilateur VT3',
      mask: 16384
    },
    {
      key: 'default4',
      name: 'Défaut',
      mask: 32768
    }
  ],
  40132: [
    {
      key: 'default5',
      name: 'Défaut',
      mask: 1
    },
    {
      key: 'default6',
      name: 'Défaut',
      mask: 2
    },
    {
      key: 'default7',
      name: 'Défaut',
      mask: 4
    },
    {
      key: 'defaultGrainTemperature',
      name: 'Défaut température grain 40302',
      mask: 8
    },
    {
      key: 'default8',
      name: 'Défaut',
      mask: 16
    },
    {
      key: 'default9',
      name: 'Défaut',
      mask: 32
    },
    {
      key: 'defaultBottomBurnerTemperature',
      name: 'Défaut seuil brûleur inférieur 40300',
      mask: 64
    },
    {
      key: 'defaultTopBurnerTemperature',
      name: 'Défaut seuil brûleur supérieur 40301',
      mask: 128
    },
    {
      key: 'defaultGotoMini',
      name: 'Défaut 1er seuil (passage en mini gaz)',
      mask: 256
    },
    {
      key: 'defaultGeneralStop',
      name: 'Défaut 2ème seuil (arrêt générale)',
      mask: 512
    },
    {
      key: 'default10',
      name: 'Défaut',
      mask: 1024
    },
    {
      key: 'default11',
      name: 'Défaut',
      mask: 2048
    },
    {
      key: 'default12',
      name: 'Défaut',
      mask: 4096
    },
    {
      key: 'default13',
      name: 'Défaut',
      mask: 8192
    },
    {
      key: 'default14',
      name: 'Défaut',
      mask: 16384
    },
    {
      key: 'default15',
      name: 'Défaut',
      mask: 32768
    }
  ],
  40133: [
    {
      key: 'defaultBottomEmergencyStop',
      name: 'Défaut arrêt d\'urgence brûleur inférieur',
      mask: 1
    },
    {
      key: 'defaultBottomBurnerBox',
      name: 'Défaut brûleur inférieur (réarmer la boîte)',
      mask: 2
    },
    {
      key: 'defaultBottomBurnerSealing',
      name: 'Défaut étanchéité brûleur inférieur',
      mask: 4
    },
    {
      key: 'defaultBottomModulation',
      name: 'Défaut fin de course modulante inférieur',
      mask: 8
    },
    {
      key: 'defaultBottomBurnerPressure',
      name: 'Défaut pression gaz brûleur inférieur',
      mask: 16
    },
    {
      key: 'default16',
      name: 'Défaut',
      mask: 32
    },
    {
      key: 'default17',
      name: 'Défaut',
      mask: 64
    },
    {
      key: 'default18',
      name: 'Défaut',
      mask: 128
    },
    {
      key: 'defaultTopEmergencyStop',
      name: 'Défaut arrêt d\'urgence brûleur supérieur',
      mask: 256
    },
    {
      key: 'defaultTopBurnerBox',
      name: 'Défaut brûleur supérieur (réarmer la boîte)',
      mask: 512
    },
    {
      key: 'defaultTopBurnerSealing',
      name: 'Défaut étanchéité brûleur supérieur',
      mask: 1024
    },
    {
      key: 'defaultTopModulation',
      name: 'Défaut fin de course modulante supérieur',
      mask: 2048
    },
    {
      key: 'defaultTopBurnerPressure',
      name: 'Défaut pression gaz brûleur supérieur',
      mask: 4096
    },
    {
      key: 'default19',
      name: 'Défaut',
      mask: 8192
    },
    {
      key: 'default20',
      name: 'Défaut',
      mask: 16384
    },
    {
      key: 'default21',
      name: 'Défaut',
      mask: 32768
    }
  ],
  40134: [
    {
      key: 'defaultTopEmergencyStop',
      name: 'Défaut arrêt d\'urgence brûleur supérieur',
      mask: 1
    },
    {
      key: 'defaultTopBurnerBox',
      name: 'Défaut brûleur supérieur (réarmer la boîte)',
      mask: 2
    },
    {
      key: 'defaultTopBurnerSealing',
      name: 'Défaut étanchéité brûleur supérieur',
      mask: 4
    },
    {
      key: 'defaultTopModulation',
      name: 'Défaut fin de course modulante supérieur',
      mask: 8
    },
    {
      key: 'defaultTopBurnerPressure',
      name: 'Défaut pression gaz brûleur supérieur',
      mask: 16
    },
    {
      key: 'default22',
      name: 'Défaut',
      mask: 32
    },
    {
      key: 'default23',
      name: 'Défaut',
      mask: 64
    },
    {
      key: 'default24',
      name: 'Défaut',
      mask: 128
    },
    {
      key: 'default25',
      name: 'Défaut',
      mask: 256
    },
    {
      key: 'default26',
      name: 'Défaut',
      mask: 512
    },
    {
      key: 'default27',
      name: 'Défaut',
      mask: 1024
    },
    {
      key: 'default28',
      name: 'Défaut',
      mask: 2048
    },
    {
      key: 'default29',
      name: 'Défaut',
      mask: 4096
    },
    {
      key: 'default30',
      name: 'Défaut',
      mask: 8192
    },
    {
      key: 'default31',
      name: 'Défaut',
      mask: 16384
    },
    {
      key: 'default32',
      name: 'Défaut',
      mask: 32768
    }
  ],
  40135: [
    {
      key: 'defaultAspiratorG1',
      name: 'Disjonction aspirateur dépoussiérage G1',
      mask: 1
    },
    {
      key: 'defaultRotation1G1',
      name: 'Disjonction rotation haut dépoussiérage G1',
      mask: 2
    },
    {
      key: 'defaultRotation2G1',
      name: 'Disjonction rotation bas dépoussiérage G1',
      mask: 4
    },
    {
      key: 'defaultAspiratorG2',
      name: 'Disjonction aspirateur dépoussiérage G2',
      mask: 8
    },
    {
      key: 'defaultRotation1G2',
      name: 'Disjonction rotation haut dépoussiérage G2',
      mask: 16
    },
    {
      key: 'defaultRotation2G2',
      name: 'Disjonction rotation bas dépoussiérage G2',
      mask: 32
    },
    {
      key: 'defaultAspiratorG3',
      name: 'Disjonction aspirateur dépoussiérage G3',
      mask: 64
    },
    {
      key: 'defaultRotation1G3',
      name: 'Disjonction rotation haut dépoussiérage G3',
      mask: 128
    },
    {
      key: 'defaultRotation2G3',
      name: 'Disjonction rotation bas dépoussiérage G3',
      mask: 256
    },
    {
      key: 'defaultAspiratorG4',
      name: 'Disjonction aspirateur dépoussiérage G4',
      mask: 512
    },
    {
      key: 'defaultRotation1G4',
      name: 'Disjonction rotation haut dépoussiérage G4',
      mask: 1024
    },
    {
      key: 'defaultRotation2G4',
      name: 'Disjonction rotation bas dépoussiérage G4',
      mask: 2048
    },
    {
      key: 'defaultAspiratorG5',
      name: 'Disjonction aspirateur dépoussiérage G5',
      mask: 4096
    },
    {
      key: 'defaultRotation1G5',
      name: 'Disjonction rotation haut dépoussiérage G5',
      mask: 8192
    },
    {
      key: 'defaultRotation2G5',
      name: 'Disjonction rotation bas dépoussiérage G5',
      mask: 16384
    },
    {
      key: 'defaultTopFilterPressure',
      name: 'Défaut pression filtres supérieur',
      mask: 32768
    }
  ],
  40136: [
    {
      key: 'defaultAspiratorG6',
      name: 'Disjonction aspirateur dépoussiérage G6',
      mask: 1
    },
    {
      key: 'defaultRotation1G6',
      name: 'Disjonction rotation haut dépoussiérage G6',
      mask: 2
    },
    {
      key: 'defaultRotation2G6',
      name: 'Disjonction rotation bas dépoussiérage G6',
      mask: 4
    },
    {
      key: 'defaultAspiratorG7',
      name: 'Disjonction aspirateur dépoussiérage G7',
      mask: 8
    },
    {
      key: 'defaultRotation1G7',
      name: 'Disjonction rotation haut dépoussiérage G7',
      mask: 16
    },
    {
      key: 'defaultRotation2G7',
      name: 'Disjonction rotation bas dépoussiérage G7',
      mask: 32
    },
    {
      key: 'defaultAspiratorG8',
      name: 'Disjonction aspirateur dépoussiérage G8',
      mask: 64
    },
    {
      key: 'defaultRotation1G8',
      name: 'Disjonction rotation haut dépoussiérage G8',
      mask: 128
    },
    {
      key: 'defaultRotation2G8',
      name: 'Disjonction rotation bas dépoussiérage G8',
      mask: 256
    },
    {
      key: 'defaultAspiratorG9',
      name: 'Disjonction aspirateur dépoussiérage G9',
      mask: 512
    },
    {
      key: 'defaultRotation1G9',
      name: 'Disjonction rotation haut dépoussiérage G9',
      mask: 1024
    },
    {
      key: 'defaultRotation2G9',
      name: 'Disjonction rotation bas dépoussiérage G9',
      mask: 2048
    },
    {
      key: 'defaultAspiratorG10',
      name: 'Disjonction aspirateur dépoussiérage G10',
      mask: 4096
    },
    {
      key: 'defaultRotation1G10',
      name: 'Disjonction rotation haut dépoussiérage G10',
      mask: 8192
    },
    {
      key: 'defaultRotation2G10',
      name: 'Disjonction rotation bas dépoussiérage G10',
      mask: 16384
    },
    {
      key: 'default33',
      name: 'Défaut',
      mask: 32768
    }
  ],
  40137: [
    {
      key: 'defaultEmptyColumn1',
      name: 'Défaut vide colonne séchoir',
      mask: 1
    },
    {
      key: 'defaultEmptyColumn2',
      name: 'Défaut vide colonne séchoir',
      mask: 2
    },
    {
      key: 'defaultReddlerStopped',
      name: 'Défaut arrêt reprise sous séchoir',
      mask: 4
    }
  ]
  /*,
  40133: [
    {
      key: 'defaultCom',
      name: 'Défaut communication PC/Automate',
      mask: 2
    },
    {
      key: 'defaultES',
      name: 'Défaut automate entrées/sorties/PT100',
      mask: 8
    },
    {
      key: 'defaultFilter1DoorOpen',
      name: 'Défaut porte 1 dépoussièrage ouverte',
      mask: 16
    },
    {
      key: 'defaultFilter2DoorOpen',
      name: 'Défaut porte 2 dépoussiérage ouverte',
      mask: 32
    },
    {
      key: 'defaultBottomBurnerDoorOpen',
      name: 'Défaut porte brûleur inférieur ouverte',
      mask: 64
    },
    {
      key: 'defaultTopBurnerDoorOpen',
      name: 'Défaut porte brûleur supérieur ouverte',
      mask: 128
    },
    {
      key: 'defaultFilledDrains',
      name: 'Défaut trémies pleines',
      mask: 256
    }
  ],
  40134: [
    {
      key: 'defaultBottomBurnerVentilation',
      name: 'Défaut inférieur : pré-ventilation',
      mask: 1
    },
    {
      key: 'defaultBottomBurnerPilot',
      name: 'Défaut inférieur : allumage pilote',
      mask: 2
    },
    {
      key: 'defaultBottomBurnerLightning',
      name: 'Défaut inférieur : allumage rampe',
      mask: 4
    },
    {
      key: 'defaultBottomBurnerWorking',
      name: 'Défaut inférieur : en régime',
      mask: 8
    },
    {
      key: 'defaultBottomBurnerExtracting',
      name: 'Défaut inférieur : à l\'extraction',
      mask: 16
    },
    {
      key: 'defaultBottomBurnerBoxStarting',
      name: 'Défaut inférieur : pas de démarrage boite',
      mask: 32
    },
    {
      key: 'defaultBottomBurnerModulating',
      name: 'Défaut inférieur : montée modulante',
      mask: 64
    },
    {
      key: 'defaultBottomBurnerMisc',
      name: 'Défaut inférieur : ',
      mask: 128
    },
    {
      key: 'defaultTopBurnerVentilation',
      name: 'Défaut supérieur : pré-ventilation',
      mask: 256
    },
    {
      key: 'defaultTopBurnerPilot',
      name: 'Défaut supérieur : allumage pilote',
      mask: 512
    },
    {
      key: 'defaultTopBurnerLightning',
      name: 'Défaut supérieur : allumage rampe',
      mask: 1024
    },
    {
      key: 'defaultTopBurnerWorking',
      name: 'Défaut supérieur : en régime',
      mask: 2048
    },
    {
      key: 'defaultTopBurnerExtracting',
      name: 'Défaut supérieur : à l\'extraction',
      mask: 4096
    },
    {
      key: 'defaultTopBurnerBoxStarting',
      name: 'Défaut supérieur : pas de démarrage boite',
      mask: 8192
    },
    {
      key: 'defaultTopBurnerModulating',
      name: 'Défaut supérieur : montée modulante',
      mask: 16384
    },
    {
      key: 'defaultTopBurnerMisc',
      name: 'Défaut supérieur : ',
      mask: 32768
    }
  ]*/
};

export default MASK;