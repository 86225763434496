<template>
  <v-banner elevation="3" tile single-line>
    <v-container fluid fill-height class="pb-0">
      <v-row no-gutters>
        <v-col class="mr-1 d-none d-sm-flex"><!-- class="pa-0" -->
          <v-text-field
            :value="totalExtractions.value"
            label="Total"
            dense
            hide-details
            outlined
            readonly
            class="shrink"
            style="width: 80px"
          ></v-text-field>
        </v-col>
        <v-col class="mr-1 d-none d-sm-flex">
          <v-text-field
            :value="dailyExtractions.value"
            label="Journalier"
            dense
            hide-details
            outlined
            readonly
            class="shrink"
            style="width: 80px"
          ></v-text-field>
        </v-col>
        <v-col class="mr-1">
          <v-text-field
            :value="extractionsTimer.value"
            label="Minuterie"
            :hint="'Calculée : ' + extractionsTimerTargetCalc.value + 's'"
            persistent-hint
            dense
            outlined
            readonly
            class="shrink"
            style="width: 110px"
          >
            <v-icon slot="append" color="blue">mdi-alarm</v-icon>
          </v-text-field>
        </v-col>
        <v-col></v-col>
        <v-col class="mr-1">
          <v-text-field
            :value="grainTemp.value + '°C'"
            label="Grain"
            :hint="'Consigne : ' + grainTempTarget.value + '°C'"
            persistent-hint
            dense
            outlined
            readonly
            class="shrink"
            style="width: 110px"
          >
            <v-icon slot="append" color="yellow darken-3">mdi-corn</v-icon>
          </v-text-field>
        </v-col>
        <v-col class="mr-1">
          <v-text-field
            :value="bottomBurnerTemp.value + '°C'"
            label="Brûleur bas"
            :hint="'Consigne : ' + bottomBurnerTempTarget.value + '°C'"
            persistent-hint
            dense
            outlined
            readonly
            class="shrink"
            style="width: 120px"
          >
            <v-icon v-if="mqttData.bBurnerRunning && mqttData.bBurnerRunning.value" slot="append" color="red">mdi-fire</v-icon>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :value="topBurnerTemp.value + '°C'"
            label="Brûleur haut"
            :hint="'Consigne : ' + topBurnerTempTarget.value + '°C'"
            persistent-hint
            dense
            outlined
            readonly
            class="shrink"
            style="width: 120px"
          >
            <v-icon v-if="mqttData.tBurnerRunning && mqttData.tBurnerRunning.value" slot="append" color="red">mdi-fire</v-icon>
          </v-text-field>
        </v-col>
        <v-col>
          <v-btn v-if="mqttData.running && mqttData.running.value" icon color="green">
            <v-icon>mdi-sync-circle</v-icon>
          </v-btn>
          <v-btn v-else icon color="red">
            <v-icon>mdi-stop-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-banner>
</template>

<script>
export default {
  props: ['mqttData'],
  computed: {
    totalExtractions: function() {
      if (typeof this.mqttData['40328'] !== 'undefined') return this.mqttData['40328'];
      return {
        value: 0,
        name: ''
      };
    },
    dailyExtractions: function() {
      if (typeof this.mqttData['40315'] !== 'undefined') return this.mqttData['40315'];
      return {
        value: 0,
        name: ''
      };
    },
    extractionsTimer: function() {
      if (typeof this.mqttData['40307'] !== 'undefined') return this.mqttData['40307'];
      return {
        value: 0,
        name: ''
      };
    },
    extractionsTimerTarget: function() {
      if (typeof this.mqttData['40305'] !== 'undefined') return this.mqttData['40305'];
      return {
        value: 0,
        name: ''
      };
    },
    extractionsTimerTargetCalc: function() {
      if (typeof this.mqttData['40306'] !== 'undefined') return this.mqttData['40306'];
      return {
        value: 0,
        name: ''
      };
    },
    grainTemp: function() {
      if (typeof this.mqttData['40302'] !== 'undefined') return this.mqttData['40302'];
      return {
        value: 0,
        name: ''
      };
    },
    grainTempTarget: function() {
      if (typeof this.mqttData['40312'] !== 'undefined') return this.mqttData['40312'];
      return {
        value: 0,
        name: ''
      };
    },
    bottomBurnerTemp: function() {
      if (typeof this.mqttData['40300'] !== 'undefined') return this.mqttData['40300'];
      return {
        value: 0,
        name: ''
      };
    },
    bottomBurnerTempTarget: function() {
      if (typeof this.mqttData['40310'] !== 'undefined') return this.mqttData['40310'];
      return {
        value: 0,
        name: ''
      };
    },
    topBurnerTemp: function() {
      if (typeof this.mqttData['40301'] !== 'undefined') return this.mqttData['40301'];
      return {
        value: 0,
        name: ''
      };
    },
    topBurnerTempTarget: function() {
      if (typeof this.mqttData['40311'] !== 'undefined') return this.mqttData['40311'];
      return {
        value: 0,
        name: ''
      };
    }
  }
};
</script>

<style scoped>
 @keyframes fireFadeInOut {
  0% {transform: scale(1);}
  25% {transform: scale(.97);}
  35% {transform: scale(.9);}
  45% {transform: scale(1.1);}
  55% {transform: scale(.9);}
  65% {transform: scale(1.1);}
  75% {transform: scale(1.03);}
  100% {transform: scale(1);}
}

.v-icon.mdi-fire { 
  animation-name: fireFadeInOut; 
  animation-iteration-count: infinite;
  animation-duration: 2s;
}
</style>