<template>
  <v-app-bar
    app
    bottom
    color="green"
    hide-on-scroll
  >
    <!--<v-btn icon>
      <v-icon>mdi-play</v-icon>
    </v-btn>
    <v-btn icon>
      <v-icon>mdi-playlist-check</v-icon>
    </v-btn>-->

    <v-text-field
      v-if="isAuthenticated"
      v-model="dryerState"
      class="font-weight-black mr-3 shrink text-uppercase dryer-state-text"
      dense
      disabled
      hide-details
      outlined
      prepend-inner-icon="mdi-cogs"
      single-line
    ></v-text-field>

    <!-- stop button -->
    <v-btn
      v-if="isAuthenticated" @click="stop"
      color="red"
      depressed
      :loading="loading"
      :disabled="!dryerRunning"
      class="white--text"><!-- bug using "dark" prop make it disapears with "disabled" -->
      <v-icon dark left>mdi-stop-circle</v-icon> Arrêt
      <template v-slot:loader>
        <span>Envoi...</span>
      </template>
    </v-btn>

    <!-- context action button -->
    <v-btn v-if="fab.action"
      @click="fabClick"
      bottom
      :color="fab.color"
      fab
      fixed
      right
    >
      <v-icon color="white">{{fab.icon}}</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  data: function() {
    return {
      mqttData: [],
      loading: false,
      loadingSnackbar: false,
      snackbarText: 'Chargement...'
    };
  },

  computed: {
    dryerRunning: function() {
      if (typeof this.mqttData.running !== 'undefined') {
        return this.mqttData.running.value;
      }

      return false;
    },
    dryerState: function() {
      // @todo defined more states
      if (typeof this.mqttData.running !== 'undefined') {
        return 'En fonctionnement';
      }

      return 'A l\'arrêt';
    },
    fab: function() {
      return this.$store.getters['fab/get'];
    },
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    }
  },

  methods: {
    fabClick: function() {
      const fab = this.$store.getters['fab/get'];
      if (fab.action) {
        // @todo do action
        this.$store.dispatch('fab/click', fab.action);
      }

      return false;
    },

    stop: function() {
      this.snackbarText = 'Arrêt du séchoir...';
      this.loadingSnackbar = true;

      this.loading = true;
      console.log('mqtt-publish: stopping dryer + cooling');

      // @todo registers must be changed (modbus commands) by masking their previous values, ie. if 124 is 37 and we need to ack_alarms: bit for 4,
      // ie. we must change the 3rd bit to 0
      // ex. => 0010 0101 (37) [alarms acknowledgeable on] => 0010 0001 (33) [ack alarms]
      if (typeof this.mqttData['40124'] === 'undefined') { // @todo should wait for a value (promise)
        return false;
      }

      const currentVal = this.mqttData['40124'].value;
      const newVal = currentVal ^ 1; // mask: 0b00000001
      console.log('Register #124 was:', currentVal, ', now it will be set to:', newVal);
      console.log('Message we will send: 124=>', newVal);

      // send command via MQTT
      this.$store.dispatch('mqttPublish', {
        topic: 'coopta/dryer/commands/register',
        msg: '124=>' + newVal,
        cb: function(error) { console.log(error); }
      }).then(function() {
        console.log('dryer stopped (and cooling) successfully');
        this.loading = false;
      }).catch(function(error) {
        // @todo
        console.log(error);
      });
    }
  }
};
</script>

<style>
.dryer-state-text .v-text-field__slot {
  margin-left: 5px;
}
</style>