<template>
  <v-toolbar dense flat tile>
    <p class="ma-0 text-body-2" style="color: #464c54;">{{title}}
      <span class="text-caption" style="color: #7b8087;">&nbsp;{{subtitle}}</span>
    </p>

    <v-spacer></v-spacer>

    <v-btn-toggle active-class="no-active" dense multiple>
      <!-- pan left -->
      <v-btn @click="panLeft" background-color="grey" class="pa-0" depressed min-width="26" small tile>
        <v-icon small>mdi-chevron-left</v-icon>
      </v-btn>

      <!-- time range menu -->
      <v-menu
        v-model="timeRangeMenuState"
        bottom
        :close-on-content-click="false"
        left
        min-width="540"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn background-color="grey" class="ml-0 py-0" v-bind="attrs" v-on="on" depressed small tile>
            <v-icon left small>mdi-clock-outline</v-icon>
            {{range.from}} au {{range.to}}
            <v-icon right small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <TimeRangeCard v-on:update:success="timeRangeMenuState = $event"/>
      </v-menu>

      <!-- pan right -->
      <v-btn @click="panRight" background-color="grey" class="ml-0 pa-0" depressed min-width="26" small tile>
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>

      <!-- unzoom -->
      <v-btn @click="unZoom" background-color="grey" class="ml-0 pa-0" depressed min-width="26" small tile>
        <v-icon small>mdi-magnify-minus-outline</v-icon>
      </v-btn>
    </v-btn-toggle>

    <!-- auto-refresh -->
    <v-btn-toggle active-class="no-active" class="ml-1" dense multiple>
      <v-btn @click="refresh" background-color="grey" class="py-0" depressed min-width="26" small tile>
        <v-icon small>mdi-autorenew</v-icon>
      </v-btn>
      <v-menu
        bottom
        :close-on-content-click="true"
        left
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn background-color="grey" class="ml-0 py-0 px-2" v-bind="attrs" v-on="on" depressed min-width="25" small tile>
            {{refreshIntervalAsText}}
            <v-icon right small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
				<v-list dense>
					<v-list-item-group
						v-model="refreshInterval"
					>
            <v-list-item
              v-for="(item, i) in refreshItems"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-btn-toggle>

    <!-- download button -->
    <!-- @todo implement download-svg event! -->
    <v-btn-toggle active-class="no-active" class="ml-1" dense multiple>
      <v-btn @click="$emit('download-png')" background-color="grey" class="pa-0" dense depressed min-width="26" small tile>
        <v-icon small>mdi-download</v-icon>
      </v-btn>
    </v-btn-toggle>
  </v-toolbar>
</template>

<script>
import TimeRangeCard from '@/components/Chart/TimeRangeCard';

export default {
  name: 'chart-toolbar',

  components: {
    TimeRangeCard
  },

  data: function() {
    return {
      refreshInterval: 0,
      refreshIntervalAsText: '',
      refreshItems: [
        { text: 'Off', interval: 0 },
        { text: '5s', interval: 5 },
        { text: '10s', interval: 10 },
        { text: '30s', interval: 30 },
        { text: '1m', interval: 60 },
        { text: '5m', interval: 300 },
        { text: '15m', interval: 900 },
        { text: '30m', interval: 1800 },
        { text: '1h', interval: 3600 },
        { text: '2h', interval: 7200 },
        { text: '12h', interval: 43200 }
      ],
      subtitle: '',
      timeRangeMenuState: false,
      title: ''
    };
  },

  computed: {
    range: function() {
      return this.$store.getters.getRangeLocal;
    }
  },

  watch: {
    refreshInterval: function(index) {
      const item = this.refreshItems[index];

      this.refreshIntervalAsText = item.text;
      if (index === 0) this.refreshIntervalAsText = '';

      // update store state
      this.$store.dispatch('updateRefreshInterval', item.interval);
    }
  },

  methods: {
    panLeft: function() {
      const range = this.$store.getters.getRange;
      const oldFrom = new Date(range.from).getTime();
      const oldTo = new Date(range.to).getTime();
      const interval = (oldTo - oldFrom) / 2; // in ms

      // update store state
      this.$store.dispatch('updateRange', {
        from: new Date(oldFrom - interval).toISOString(),
        to: new Date(oldTo - interval).toISOString()
      });
    },
    panRight: function() {
      const range = this.$store.getters.getRange;
      const oldFrom = new Date(range.from).getTime();
      const oldTo = new Date(range.to).getTime();
      const interval = (oldTo - oldFrom) / 2; // in ms
      
      // update store state
      this.$store.dispatch('updateRange', {
        from: new Date(oldFrom + interval).toISOString(),
        to: new Date(oldTo + interval).toISOString()
      });
    },
    unZoom: function() {
      const range = this.$store.getters.getRange;
      const oldFrom = new Date(range.from).getTime();
      const oldTo = new Date(range.to).getTime();
      const interval = (oldTo - oldFrom) / 2; // in ms
      
      // update store state
      this.$store.dispatch('updateRange', {
        from: new Date(oldFrom - interval).toISOString(),
        to: new Date(oldTo + interval).toISOString()
      });
    },
    refresh: function() {
      // clone
      const range = Object.assign({}, this.$store.getters.getRange);

      // update store state
      this.$store.dispatch('updateRange', range);
    }
  }
}
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
/* prevent active state for all buttons in button group */
.v-btn--active.no-active:not(:focus):not(:hover)::before
{                                                                             
  opacity: 0 !important;
}
</style>