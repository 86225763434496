<template>
  <div>
    <ChartToolbar v-bind:range.sync="range"
      v-on:download-png="download('png')"
      v-on:download-svg="download('svg')"
    />
    <!--<apexcharts ref="rchart" type="line" :options="options" :series="series"></apexcharts>-->
    <apexcharts id="chart-sensor"
      width="100%"
      height="350px"
      ref="rchart"
      type="line"
      :options="options"
      :series="series">
    </apexcharts>
  </div>
</template>

<script>
import { setIntervalAsync, clearIntervalAsync } from 'set-interval-async/dynamic';
import VueApexCharts from 'vue-apexcharts';
import ChartToolbar from '@/components/Chart/Toolbar';
import ChartDataService from '@/services/ChartDataService';

export default {
  name: 'sensor-chart',

  components: {
    apexcharts: VueApexCharts,
    ChartToolbar
  },

  props: [
    'sensor'
  ],

  data: function() {
    return {
      // chart options
      options: {
        chart: {
          id: 'temp-sensor', // used as filename
          toolbar: {
            show: false
          },
          redrawOnParentResize: true,
          events: {
            // @todo mount event does not fire!
            beforeMount: function() {
              console.log('=> before-mount');
            },
            mounted: function(/*chartContext, config*/) {
              console.log('=> mounted');
              /*chartContext.hideSeries('Brûleurxinférieur');
              chartContext.toggleSeries('Grain');
              chartContext.toggleSeries(40509);
              console.log(chartContext);
              chartContext.hideSeries('Grain');
              chartContext.hideSeries(40509);*/
            },
            updated: (/*chartContext, config*/) => {
              console.log('=> updated');
              //console.log(chartContext);

              if (this.loading) {
                this.loading = false;
                //this.$refs.rchart.toggleSeries('Grain');
                //this.$refs.rchart.toggleSeries(40509);
                //chartContext.toggleSeries("Grain");
                this.$apexcharts.exec('chart-sensor', 'hideSeries', 'Grain');
                this.$apexcharts.exec('chart-sensor', 'hideSeries', 40509);
                this.$apexcharts.exec('chart-sensor', 'hideSeries', 0);
                console.log(this.$refs.rchart);
                console.log('toggle');
              }

              /*chartContext.hideSeries('Brûleurxinférieur');
              //chartContext.toggleSeries('Grain');
              //chartContext.toggleSeries(40509);
              
              chartContext.hideSeries('Grain');
              chartContext.hideSeries(40509);*/
            },
            scrolled: (chartContext, { xaxis }) => {
              chartContext;

              let fromObj = new Date(xaxis.min);
              let toObj = new Date(xaxis.max);

              this.$store.dispatch('updateRange', {
                from: fromObj.toISOString(),
                to: toObj.toISOString()
              });

console.log(fromObj.toISOString(), toObj.toISOString());
            },
            /*selection: function(chartContext, { xaxis, yaxis }) {
              // ...
            },*/
            zoomed: (chartContext, { xaxis, yaxis }) => {
              chartContext;
              yaxis;

              let fromObj = new Date(xaxis.min);
              let toObj = new Date(xaxis.max);

              this.$store.dispatch('updateRange', {
                from: fromObj.toISOString(),
                to: toObj.toISOString()
              });

console.log(fromObj.toISOString(), toObj.toISOString());
            }
          }
        },
        colors: [
          '#69D2E7',
          '#E2C044',
          '#C5D86D',
          '#C4BBAF'
        ],
        stroke: {
          curve: 'smooth'
        },
        lineCap: 'round',
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + '°C';
            }
          },
          min: 0,
          max: 130,
          forceNiceScale: true
        },
        legend: {
          //horizontalAlign: 'left',
          showForSingleSeries: true
        },
        tooltip: {
          x: {
            format: 'dd/MM/yyyy HH:mm:ss',
            show : true // show tooltip title
          },
          /*fixed: {
            enabled: true,
            position: 'topLeft',
            offsetX: 50,
            offsetY: 15
          },*/
          shared: true,
          onDatasetHover: {
            highlightDataSeries: true
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
        },
        noData: {
          text: 'Chargement...'
        }
      },
      // chart series
      series: [],
      // chart refresh timer reference
      refreshTimer: null,
      // loading state
      loading: true
    };
  },

  computed: {
    range: function() {
      return this.$store.getters.getRange;
    },
    refreshInterval: function() {
      return this.$store.getters.getRefreshInterval;
    }
  },

  watch: {
    range: function(val) {
      console.log('range:', val);

      // update URI (to share link and/or build history)
      this.updateURI();

      // reset series
      this.series = [];
      this.initChart();
    },
    refreshInterval: function(interval) {
      console.log('refresh_interval', interval);

      // update URI (to share link and/or build history)
      this.updateURI();

      // refresh chart
      this.refreshChart(interval);
    }
  },

  mounted: function() {
    // get parameters from the query string
    let from = null;
    let to = null;
    let refresh = 0;

    if (this.$route.query.from) {
      from = this.$route.query.from;
    }
    if (this.$route.query.to) {
      to = this.$route.query.to;
    }
    if (this.$route.query.refresh) {
      refresh = this.$route.query.refresh;
    }

    console.log('qs from:', from);
    console.log('qs to:', to);
    console.log('qs refresh:', refresh);

    // display chart
    this.initChart();
  },

  methods: {
    /**
     * Update query string in the current URI address
     * It is mandatory when we need to share a link to
     * the chart with custom range values, or when we
     * want to add entries in browser history
     */
    updateURI: function() {
      // update URI (to share link and/or build history)
      this.$router.push({
        path: this.$route.path, // current path
        query: {
          from: this.range.from,
          to: this.range.to,
          refresh: this.refreshInterval
        }
      }).catch(err => { err; }); // prevent NavigationDuplicated error
    },

    /**
     * Auto-refresh the chart if a time interval is specified
     * @param {number} interval Refresh interval in seconds
     */
    refreshChart: async function(interval) {
      // clear previous timer if exist
      if (this.refreshTimer) await clearIntervalAsync(this.refreshTimer);

      // refresh is disabled
      if (interval === 0) return; // @todo this.series = []; & this.initChart() then return?

      this.refreshTimer = setIntervalAsync(
        async () => {
          console.log('refresh chart with setInterval!', interval);
          const fromTimestamp = new Date(this.range.from).getTime();
          const toTimestamp = new Date(this.range.to).getTime();
          const rangeInterval = toTimestamp - fromTimestamp;
          const now = new Date().getTime();

          console.log('new from:', new Date(now - rangeInterval).toISOString());
          console.log('new to:', new Date(now).toISOString());

          // update store state
          this.$store.dispatch('updateRange', {
            from: new Date(now - rangeInterval).toISOString(),
            to: new Date(now).toISOString() // now
          });
        },
        interval * 1000 // ms
      );
    },

    /**
     * Get data from API and fromat its response
     * Series are updated so the chart will (re-)render
     */
    initChart: function() {
      let measurements = {
        //40300: 'Brûleur inférieur',
        //40301: 'Brûleur supérieur',
        40302: 'Grain'
      };

      measurements[this.sensor.id] = this.sensor.name;

      let promises = [];
      let allSeries = [];
      let serieIndex = 0;

      for (let id in measurements) {
        if (Object.prototype.hasOwnProperty.call(measurements, id)) {
          let promise = ChartDataService.getHistory(id, this.$store.getters.getRange)
            .then((response) => {
              let data = this.formatResponse(response.data);

              allSeries[serieIndex] = {
                name: measurements[id],
                data: data
              };

              serieIndex++;
            })
            .catch((e) => {
              console.log(e);
            });
          
          promises.push(promise);
        }
      }

      // wait all responses
      Promise.all(promises).then(() => {
        this.series = allSeries; // trick to trigger reactivity

        // @todo hide by default
        /*this.$refs.rchart.hideSeries('Grain');
        this.$apexcharts.exec('chart-sensor', 'hideSeries', 'Brûleurxinférieur');
        this.$apexcharts.exec('chart-sensor', 'hideSeries', 'Grain');
        window.ApexCharts.exec('chart-sensor', 'hideSeries', 'Grain');*/
      });
    },

    /**
     * Format the API response, so it can be read by apexcharts
     * @param {Object[]} data Data to format
     * @returns {Object[]} A compatible apexcharts serie
     */
    formatResponse: function(data) {
      let out = [];

      for (let i = 0; i < data.length; i++) {
        out.push({
          x: data[i].time, // 2020-08-30 21:39:16
          y: data[i].temperature
        });
      }

      return out;
    },

    /**
     * Export chart as an image
     * @param {string} type The image type (ie. 'svg' or 'png')
     */
    download: function(type) {
      const filename = 'courbes_température_séchoir_du_'
        + this.range.from
        + '_au_'
        + this.range.to;

      if (type === 'svg') {
        // svg
        const paper = this.$refs.rchart.chart.paper();
        const svgData = paper.svg();
        const svgUrl = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgData);
        this.triggerDownload(svgUrl, filename, '.svg');
      } else {
        // png
        this.$refs.rchart.chart.dataURI().then((data) => {
          this.triggerDownload(data.imgURI, filename, '.png');
        });
      }
    },

    /**
     * Force browser file download
     * @param {string} href DataURI source
     * @param {string} filename The file name
     * @param {string} ext The file extension
     */
    triggerDownload: function(href, filename, ext) {
      const downloadLink = document.createElement('a');
      downloadLink.href = href;
      downloadLink.download = filename + ext;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }
}
</script>